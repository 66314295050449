import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { connect } from "react-redux";
import RestaurantNavComponent from "../../components/navigation/navigation-left/restaurant-navigation/restaurant-navigation";
import NavigationRightComponent from "../../components/navigation/navigation-right/navigation-right";
import ConceptHeaderComponent from "../../components/navigation/navigation-header/concept-header/concept-header";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ItemWrapper from "../../components/service-item/shared/itemWrapper";
import commonService from "../../services/common.service";
import {
  getSupplierRestaurants,
  getSupplierIngredients,
  getUnits,
  getIngredientDetails,
  orderShoppingCartItems,
  saveSupplierIngredients,
  getLabels,
  getActiveSupplierItemImports,
} from "../../services/supplier.restaurants.service";
import handleResponse from "../../services/response.service";
import { RESTAURANT_REQUEST } from "../../config/api.config";
import LoaderComponent from "../../components/loader/loader";
import { OpenDeleteModal } from "../../components/card-components/delete-card/delete-modal";
import { GREEN, PAGE_VIEW_MODE } from "../../constant/constant";
import moment from "moment";

import {
  supplierOfferingState,
  supplierOfferingStateInit,
} from "../../models/supplier.offering.model";
import ImportIngredientCard from "../../components/card-components/import-ingredient-card/import-ingredient-card";
import { ingredientClassRequest } from "../../redux/actions/choice.item.action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import { closeChatBox } from "../../app/freshChat";
import { UrlSyncHelper } from "../../helpers/urlSyncHelper";

class SupplierOfferingPage extends Component<any, any> {
  state: supplierOfferingState;
  corporateColor: string = GREEN;
  corporateStyle: string = "";
  historyUnListen: any = null;
  constructor(props: any) {
    super(props);
    this.state = supplierOfferingStateInit;
  }
  componentDidMount() {
    document.body.className = "light-theme";
    if (this.props.match.params?.pageViewMode === PAGE_VIEW_MODE.BOX_VIEW) {
      document.body.className += " b-box-view supplier-offering";
      closeChatBox();
    }
    this.setState({
      isFetching: true,
      shoppingCartArray: JSON.parse(
        localStorage.getItem(
          this.props.computedMatch.params.uuid + "_shoppingCart"
        ) || "[]"
      ),
    });
    getSupplierRestaurants(RESTAURANT_REQUEST, {
      credentials: {
        restaurantuuid: this.props?.computedMatch?.params.uuid,
      },
    }).then((data: any) => {
      this.setState({
        isFetching: false,
        supplierRestaurants: data.data,
      });
    });

    getActiveSupplierItemImports(RESTAURANT_REQUEST, {
      restaurantuuid: this.props?.computedMatch?.params.uuid,
    }).then((data) => {
      const responseData: any = handleResponse(data);
      if (responseData?.ok) {
        this.setState({
          supplierActiveImports: responseData.data,
        });
      }
    });
    this.getItemsListByUrl();
    this.historyUnListen = this.props.history.listen(
      (location: any, action: any) => {
        if (action === "POP" || (action === "PUSH" && location.search === "")) {
          this.getItemsListByUrl(location);
        }
      }
    );
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.supplierRestaurants !== this.props.supplierRestaurants) {
      this.setState({
        isFetching: false,
      });
    }
  }

  componentWillUnmount() {
    if (this.historyUnListen) {
      this.historyUnListen();
    }
  }

  setPageState(name: string, value: any): void {
    this.setState({ [name]: value });
  }

  getItemsListByUrl(location?: Function): void {
    const { pageNo, filter } = UrlSyncHelper.setStateFromUrl(
      this.setPageState.bind(this),
      this.setFilterParams.bind(this),
      location ?? this.props.location
    );
    if (filter.supplierArray?.length === 1) {
      this.getSupplierIngredientList(
        pageNo,
        filter,
        !!this.state.alphabeticalSortFlag ? "name:asc" : ""
      );
    } else {
      this.setState({
        supplier: {},
        currentPage: 0,
        supplierIngredients: [],
        hasPagination: false,
        isFetching: false,
        isFilterApplied: false,
        totalPages: 0,
        totalCount: 0,
        labels: [],
      });
      setTimeout(() => {
        this.setPageState("isBrowserNav", false);
      }, 500);
    }
  }

  public getPageTitle = () => {
    return {
      addButtonTitle: "",
      pageTitle: this.props.t("common.supplierOffering"),
    };
  };

  handleSelectChange = (supplier: any) => {
    if (!supplier?.value) return;
    this.setCorporateStyle(supplier);
    this.setState(
      {
        hideFilter: false,
        isFetching: true,
        supplier: supplier,
        isFilterApplied: false,
      },
      () => {
        getLabels(RESTAURANT_REQUEST, {
          credentials: {
            supplieruuid: this.state.supplier.value,
            restaurantuuid: this.props.computedMatch.params.uuid,
          },
        }).then((data: any) => {
          this.setState({
            labels: data.data,
          });
        });
        this.getSupplierIngredientList(1);
      }
    );

    getUnits(RESTAURANT_REQUEST, {
      credentials: {
        supplieruuid: supplier.value,
        restaurantuuid: this.props.match.params.uuid,
      },
    }).then((data: any) => {
      this.setState({
        supplierUnits: data.data,
      });
    });
  };

  setCorporateStyle = (supplier: any) => {
    if (
      this.props.match.params?.pageViewMode !== PAGE_VIEW_MODE.BOX_VIEW ||
      !supplier?.branding?.corporateColor
    )
      return;
    this.corporateColor = supplier.branding.corporateColor;
    this.corporateStyle = `
          <style>
          .supplier-offering {
            .btn-info, .btn-info:hover, .btn-info:active, .btn-info:focus, .badge-info, .pagination .page-item.active .page-link.b-background-green {
              background-color: ${supplier.branding.corporateColor};
              border-color: ${supplier.branding.corporateColor};
            }
            .bf-sticky-filter h4, .btn.btn-link, .nav-tabs .nav-link.active, .nav-tabs .nav-link, .page-link, .page-item:not(.active) .page-link:hover {
              color: ${supplier.branding.corporateColor};
            }
          }
          </style>
        `;
  };

  getSupplierIngredientList = (
    pageNo: number,
    filter?: any,
    sortCriteria?: string
  ) => {
    if (!this.state?.supplier?.value) return;
    this.setState({
      isFetching: true,
    });
    const sortString =
      sortCriteria !== undefined
        ? sortCriteria
        : !!this.state.alphabeticalSortFlag
        ? "name:asc"
        : "";
    const filterData = {
      labelArray: filter?.labelArray
        ? filter?.labelArray?.map((item: any) => {
            return item?.value;
          })
        : "[]",
      name: !!filter?.name?.trim() ? filter?.name?.trim() : "",
      pageNo: pageNo,
      status: filter?.status ? filter?.status?.value : "",
    };
    const payLoad = {
      credentials: {
        ...filterData,
        supplieruuid: this.state.supplier.value,
        restaurantuuid: this.props.computedMatch.params.uuid,
        sort: sortString,
      },
    };
    getSupplierIngredients(RESTAURANT_REQUEST, payLoad).then((data) => {
      if (data.status === 200) {
        this.setState({
          currentPage: pageNo,
          supplierIngredients: data.data?.items,
          hasPagination: true,
          isFetching: false,
          isFilterApplied: filter ? true : false,
          totalPages: Math.ceil(Number(data?.data?.total) / 20),
          totalCount: Number(data?.data?.total),
        });
      }
      if (!this.state.isBrowserNav) {
        UrlSyncHelper.setUrlFromState(
          { ...filterData, supplierArray: this.state.supplier.value },
          this.props.history
        );
      }
      this.setState({ isBrowserNav: false });
    });
  };

  setFilterParams = (filterParams: any) => {
    this.setState({
      filterParams,
    });
  };

  public getDetails = (recipeId: any, isOrder?: boolean, item?: any) => {
    this.setState({ isFetching: true });
    const payLoad = {
      credentials: {
        ingredientuuid: recipeId,
        restaurantuuid: this.props.match.params.uuid,
        supplieruuid: this.state.supplier.value,
      },
    };
    getIngredientDetails(RESTAURANT_REQUEST, payLoad).then((data: any) => {
      this.setState(
        {
          itemsDetails: [...this.state.itemsDetails, data.data],
          isFetching: false,
        },
        () => {
          if (isOrder) {
            item.tags = data.data?.tags;
            item["output_quantity"] = this.getOfferingOutputQuantity(item);
            this.setState({
              selectedRecipe: JSON.parse(JSON.stringify(item) || "{}"),
              showOrderModal: true,
            });
          }
        }
      );
    });
  };

  getOfferingOutputQuantity = (item: any) => {
    if (item?.offered_unit) {
      return 1;
    }
    return item.output_quantity;
  };

  setCurrentPage = (currentPage: number) => {
    this.setState({
      currentPage: currentPage,
    });
  };

  public handleSelect = (key: any, recipeId: any) => {
    this.setState({ elementId: recipeId });
    if (key === "tags") this.getDetails(recipeId);
  };

  public getCostDetails = (ingredientId: any) => {
    this.setState({ ingredientId: ingredientId });
    this.props.getIngredientCost({
      restaurantuuid: this.state.supplier.value,
      uuid: ingredientId,
    });
  };

  toggleIngredientImportModal = (item?: any) => {
    this.setState((prevState: supplierOfferingState) => {
      return {
        currentItem: !!item ? item : {},
        isModalOpen: !prevState.isModalOpen,
      };
    });
  };

  getSubstituteOptions = () => {
    const options: any =
      this.props.productSubstitute?.length > 0 &&
      this.props.productSubstitute?.map((item: any) => {
        return {
          value: item.uuid,
          label: commonService.applyLocalization(
            "restaurant",
            "name",
            item?.locales
          ).name,
        };
      });
    return !!options ? options : [];
  };

  handleIngredientImportSave = (substitutes: any) => {
    const item: any = this.state.currentItem;
    this.setState({
      isFetching: true,
    });
    const productSubstitute = substitutes?.map((sub: any) => sub.value);

    const payLoad = {
      credentials: {
        supplieruuid: this.state.supplier.value,
        restaurantuuid: this.props.match.params.uuid,
        data: {
          ingredients: [
            {
              service_set: item.service_set,
              service_set_recipe: item.service_set_recipe,
              ingredient: item.uuid,
              product_substitute: productSubstitute,
            },
          ],
        },
      },
    };
    saveSupplierIngredients(RESTAURANT_REQUEST, payLoad).then((data) => {
      handleResponse(data);
      if (data.status === 200) {
        this.setState({
          isFetching: false,
        });
        this.toggleIngredientImportModal();
      }
    });
  };

  openOrderModal = (item: any) => {
    if (
      this.state.itemsDetails.findIndex(
        (recipe: any) =>
          recipe.uuid === item.uuid &&
          recipe?.offered_unit === item?.offered_unit
      ) === -1
    ) {
      this.getDetails(item.uuid, true, item);
    } else {
      item["output_quantity"] = this.getOfferingOutputQuantity(item);
      this.setState({
        showOrderModal: true,
        selectedRecipe: JSON.parse(JSON.stringify(item) || "{}"),
      });
    }
    this.setState({
      selectedIndex: "",
    });
  };

  toggleOrderModal = () => {
    this.setState((prevState: any) => ({
      showOrderModal: !prevState.showOrderModal,
    }));
  };
  toggleCartModal = () => {
    this.setState((prevState: any) => ({
      showCartModal: !prevState.showCartModal,
    }));
  };

  handleChange = (e: any, changeSelectedRecipe: boolean = false) => {
    if (e.target.name === "quantity" || changeSelectedRecipe === true) {
      const { selectedRecipe } = this.state;
      selectedRecipe[e.target.name] = e.target.value;
      this.setState({
        selectedRecipe,
      });
    }
    if (e.target.name === "supplierActiveImports") {
      this.setState({
        supplierActiveImports: e.target.value,
      });
    }
  };

  handleOverridableTags = (tag: any) => {};

  openCartModal = () => {
    this.setState({
      showCartModal: true,
    });
  };

  saveOrder = () => {
    const { selectedRecipe } = this.state;
    selectedRecipe["supplierList"] = true;
    var cartItems: any = [];
    if (this.state.selectedIndex || this.state.selectedIndex === 0) {
      cartItems = this.state.shoppingCartArray;
      cartItems.splice(this.state.selectedIndex, 1, selectedRecipe);
    } else {
      cartItems = [...this.state.shoppingCartArray, selectedRecipe];
    }
    this.setState(
      (prevState: any) => ({
        shoppingCartArray: cartItems,
      }),
      () => {
        localStorage.setItem(
          this.props.computedMatch.params.uuid + "_shoppingCart",
          JSON.stringify(this.state.shoppingCartArray)
        );
        commonService.toastService(
          this.props.t("toast.itemAddedToCart"),
          "success"
        );
        this.setState({
          isUpdated: true,
        });
      }
    );
    this.toggleOrderModal();
  };

  placeOrder = () => {
    this.setState({
      isFetching: true,
    });
    var formattedCartArray = this.state.shoppingCartArray.map((recipe: any) => {
      return {
        recipe: recipe.uuid,
        recipeName: commonService.applyLocalization(
          "restaurant",
          "name",
          recipe?.locales
        )["name"],
        orderUnit: recipe?.unit_symbol ?? undefined,
        orderUnitUuid: recipe.offered_unit,
        quantity: parseFloat(recipe.quantity),
        serviceSet: recipe.service_set,
        serviceSetRecipe: recipe.service_set_recipe,
        type: 2,
        supplierList: recipe?.supplierList,
        tags: !!recipe.tags
          ? recipe.tags
              .filter((tag: any) => tag?.recipe_tag_meta?.override)
              ?.map((tag: any) => ({
                tag: tag.tag?.uuid,
                key: tag.tag?.key,
                name: commonService.applyLocalization(
                  "restaurant",
                  "name",
                  tag?.tag?.locales
                )["name"],
                value:
                  tag?.recipe_tag_meta?.value ||
                  tag?.recipe_tag_meta?.value === false ||
                  tag?.recipe_tag_meta?.value === ""
                    ? tag?.recipe_tag_meta?.type === 3
                      ? tag?.recipe_tag_meta?.value.value ||
                        tag?.recipe_tag_meta?.value
                      : String(tag?.recipe_tag_meta?.value)
                    : tag?.recipe_tag_meta?.default ||
                      tag?.recipe_tag_meta?.default === false
                    ? String(tag?.recipe_tag_meta?.default)
                    : "",
              }))
          : undefined,
      };
    });

    let body = {
      ingredients: formattedCartArray,
      expectedDeliveryTime: moment(
        this.state.deliveryDate,
        "YYYY-MM-DD|kk:mm"
      ).format(),
      orderComment: this.state.orderComment,
    };

    orderShoppingCartItems(RESTAURANT_REQUEST, {
      credentials: {
        restaurantuuid: this.props?.computedMatch?.params.uuid,
        data: body,
      },
    })
      .then((data: any) => {
        handleResponse(data);
        this.setState({
          deliveryDate: "",
          isFetching: false,
        });
        if (data.status === 200) {
          this.setState({
            shoppingCartArray: [],
            orderComment: "",
          });
          localStorage.removeItem(
            this.props.computedMatch.params.uuid + "_shoppingCart"
          );
        }
      })
      .catch((error: any) => {
        this.setState({
          isFetching: false,
        });
      });
    this.toggleCartModal();
  };

  removeCartItem = (index: any) => {
    OpenDeleteModal().then(() => {
      var cartItems = this.state.shoppingCartArray;
      cartItems.splice(index, 1);
      this.setState({
        shoppingCartArray: cartItems,
        orderComment: "",
      });
      localStorage.setItem(
        this.props.computedMatch.params.uuid + "_shoppingCart",
        JSON.stringify(cartItems)
      );
    });
  };

  handleDateChange = (value: any) => {
    this.setState({
      deliveryDate: value,
    });
  };

  handleCommentChange = (e: any) => {
    this.setState({
      orderComment: e.target.value,
    });
  };

  setAlphabeticalSortFlag = () => {
    const alphabeticalSort = !this.state.alphabeticalSortFlag;
    this.setState({
      alphabeticalSortFlag: alphabeticalSort,
    });
    if (!!this.state.supplier?.value) {
      this.getSupplierIngredientList(
        1,
        this.state.filterParams,
        alphabeticalSort ? "name:asc" : ""
      );
    }
  };

  handleTagChange = (editedTag: any, value: any) => {
    const { selectedRecipe } = this.state;

    var selectedTag = selectedRecipe?.tags?.find(
      (tag: any) => tag.uuid === editedTag.uuid
    );
    selectedTag.recipe_tag_meta.value = value;
    this.setState(
      {
        selectedRecipe,
      },
      () => {
        this.setState({
          isUpdated: true,
        });
      }
    );
  };

  editCartItem = (item: any, index: any) => {
    this.setState({
      selectedIndex: index,
    });
    const selectedRecipe = this.state.shoppingCartArray[index];
    selectedRecipe["output_quantity"] =
      this.getOfferingOutputQuantity(selectedRecipe);
    this.setState({
      showCartModal: false,
      selectedRecipe,
    });
    this.toggleOrderModal();
  };

  clearShoppingCart = () => {
    this.setState({
      shoppingCartArray: [],
      orderComment: "",
    });
    localStorage.removeItem(
      this.props.computedMatch.params.uuid + "_shoppingCart"
    );
  };
  render() {
    const { t } = this.props;
    const {
      itemsDetails,
      selectedRecipe,
      showCartModal,
      shoppingCartArray,
      showOrderModal,
    } = this.state;
    const cartItem = JSON.parse(
      localStorage.getItem(
        this.props.computedMatch.params.uuid + "_shoppingCart"
      ) || "[]"
    );
    return (
      <>
        <LoaderComponent display={!!this.state.isFetching} />
        <ImportIngredientCard
          isOpen={this.state.isModalOpen}
          toggle={this.toggleIngredientImportModal}
          save={this.handleIngredientImportSave}
          options={this.getSubstituteOptions()}
        />

        <div className="container-fluid">
          <ConceptHeaderComponent concept={"concept"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max b-left-nav-wrapper">
              <RestaurantNavComponent
                display={"supplier-offering"}
                restaurantuuid={this.props?.computedMatch?.params.uuid}
              />
            </Col>

            <Col xl={8} lg={6} className="b-content-wrapper">
              <ItemWrapper
                area="supplier-offering"
                currentPage={this.state.currentPage}
                filterParams={this.state.filterParams}
                hasDetails={false}
                hasPagination={this.state.hasPagination}
                hasTag={true}
                hideFilter={this.state.hideFilter}
                isSupplierOffering={true}
                isFilterApplied={this.state.isFilterApplied}
                pageText={this.getPageTitle()}
                itemData={this.state.supplierIngredients}
                itemsDetails={this.state.itemsDetails}
                units={this.state.supplierUnits}
                handleSelect={this.handleSelect}
                handleIngredientImport={this.toggleIngredientImportModal}
                handleSelectChange={this.handleSelectChange}
                getItems={this.getSupplierIngredientList}
                openCartModal={this.openCartModal}
                openOrderModal={this.openOrderModal}
                restaurantOptions={
                  this.state.supplierRestaurants.length > 0
                    ? this.state.supplierRestaurants.map((item: any) => ({
                        value: item.uuid,
                        label: commonService.applyLocalization(
                          "restaurant",
                          "name",
                          item.locales
                        )["name"],
                        branding: item.branding,
                      }))
                    : []
                }
                setCurrentPage={this.setCurrentPage}
                setFilterParams={this.setFilterParams}
                showItemCount={true}
                supplier={this.state.supplier}
                tags={this.props?.tags}
                totalPages={this.state.totalPages}
                totalCount={this.state.totalCount}
                clearShoppingCart={this.clearShoppingCart}
                deliveryDate={this.state.deliveryDate}
                editCartItem={this.editCartItem}
                handleChange={this.handleChange}
                handleTagChange={this.handleTagChange}
                handleDateChange={this.handleDateChange}
                handleCommentChange={this.handleCommentChange}
                placeOrder={this.placeOrder}
                removeCartItem={this.removeCartItem}
                showCartModal={showCartModal}
                showOrderModal={showOrderModal}
                saveOrder={this.saveOrder}
                selectedRecipe={selectedRecipe}
                shoppingCartArray={shoppingCartArray}
                supplierUnits={this.state.supplierUnits}
                toggleOrderModal={this.toggleOrderModal}
                toggleCartModal={this.toggleCartModal}
                quantity={selectedRecipe.quantity}
                labels={this.state.labels}
                supplierLabels={this.state.labels}
                alphabeticalSortFlag={this.state.alphabeticalSortFlag}
                setAlphabeticalSortFlag={this.setAlphabeticalSortFlag.bind(
                  this
                )}
                supplierActiveImports={this.state.supplierActiveImports}
                isBrowserNav={this.state.isBrowserNav}
              />
            </Col>
            <Col xl={2} lg={3} className="b-right-aside-wrapper">
              <NavigationRightComponent />
            </Col>
            {cartItem.length > 0 && !showOrderModal && !showCartModal && (
              <div className="b-shopping-cart d-flex align-items-center position-fixed pointer-cursor">
                {" "}
                <FontAwesomeIcon
                  className="bf-font-size-45 info"
                  style={{ color: this.corporateColor, margin: 5 }}
                  icon={faCartPlus}
                  onClick={this.openCartModal}
                />
              </div>
            )}
          </Row>
        </div>
        <div dangerouslySetInnerHTML={{ __html: this.corporateStyle }} />
      </>
    );
  }
}

const mapStateToProps: any = (state: any) => {
  let isFetching = state.ingredientClass.isFetching;
  let failure = state.ingredientClass.failure;
  return {
    isFetching: isFetching,
    failure: failure,
    productSubstitute: state.ingredientClass.data,
    restaurantLang: commonService.getRestaurantDetails()?.languages || [],
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    getIngredientClass: (credentials: any) => {
      dispatch(ingredientClassRequest(credentials));
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(SupplierOfferingPage))
);
