import "../../styles/restaurant.scss";

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Button, Col, Form, Label, Row } from "reactstrap";

import {
  RestaurantInitState,
  RestaurantState,
} from "../../models/restaurant.model";
import {
  restaurantAdd,
  restaurantRemove,
  restaurantRequest,
  restaurantUpdate,
} from "../../redux/actions/restaurant.action";
import { upload } from "../../redux/actions/upload.action";
import commonService from "../../services/common.service";
import DeleteModalCard from "../card-components/delete-card/delete-card";
import ImageModalCard from "../card-components/image-card/image-card";
import RestaurantAddCard from "../card-components/restaurant-add-card/restaurant-add-card";
import RestaurantCard from "../card-components/restaurant-card/restaurant-card";
import RestaurantEditCard from "../card-components/restaurant-edit-card/restaurant-edit-card";
import LoaderComponent from "../loader/loader";
import RestaurantHeaderComponent from "../navigation/navigation-header/restaurant-header/restaurant-header";
import NavigationRightComponent from "../navigation/navigation-right/navigation-right";
import RestaurantLeftNavComponent from "../navigation/navigation-left/restaurant-navigation/restaurant-left-navigation";
import CopyModalCard from "../card-components/copy-card/copy-card";
import { create } from "apisauce";
import { BASE_URL } from "../../config/app.config";
import { ACCESS_TOKEN, ISSUPERADMIN } from "../../constant/constant";
import handleResponse from "../../services/response.service";
import i18next from "i18next";
import { withTranslation } from "react-i18next";
import { setLanguage } from "../../config/lang.config";
import { languageRequest } from "../../redux/actions/language.action";
const api = create({
  baseURL: BASE_URL,
});
class RestaurantComponent extends Component<any> {
  state: RestaurantState;
  constructor(props: any) {
    super(props);
    this._setFunctionBindings();
    this.state = RestaurantInitState;
    api.setHeaders({
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      "Cache-Control": "no-cache",
      Locale: localStorage.getItem("i18nextLng") || "en-gb",
      "content-type": "application/json",
    });
  }
  render() {
    const { t } = this.props;

    return (
      <div>
        <LoaderComponent display={!!this.state.isFetching} />
        <div className="container-fluid">
          <RestaurantHeaderComponent />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <RestaurantLeftNavComponent display="restaurants" />
            </Col>
            <Col xl={8} lg={6}>
              <Link
                to="#"
                color="info"
                className="resturant-add"
                onClick={this.addToggle}
              >
                {t("restaurant.addRestaurant")}
              </Link>
              <div
                className="white-box mb-3"
                style={
                  this.state.restaurant.length > 0
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <Form>
                  <CopyModalCard
                    isOpen={this.state.copymodel}
                    isFetching={!!this.props.isFetching}
                    okDelete={this.okCopyRestuarant.bind(this)}
                    cancelDelete={this.copyRestuarantToggle.bind(this)}
                  />
                  <h4>{t("restaurant.restaurants")}</h4>
                  {this.restaurantList(this.state.restaurant)}
                </Form>
              </div>
            </Col>
            <Col xl={2} lg={3}>
              <NavigationRightComponent></NavigationRightComponent>
            </Col>
          </Row>
        </div>

        {/*  Edit Restaurant  */}

        <RestaurantEditCard
          state={this.state}
          toggle={this.toggle}
          handleChange={this.handleChange}
          handleCountryChange={this.handleCountryChange}
          saveEdit={this.saveEdit}
          languages={this.props.languages}
        />

        {/*  Add Restaurant  */}

        <RestaurantAddCard
          state={this.state}
          handleChange={this.handleChange}
          handleCountryChange={this.handleCountryChange}
          handleLanguageChange={this.handleLanguageChange}
          addToggle={this.addToggle}
          saveAdd={this.saveAdd}
          languages={this.props.languages}
        />

        {/* Delete Restaurant */}

        <DeleteModalCard
          isOpen={this.state.open}
          isFetching={!!this.state.isFetching}
          okDelete={this.deleteConfirmToggle}
          cancelDelete={this.deleteToggle}
        />

        {/* Image Upload Modal */}

        <ImageModalCard
          state={this.state}
          editImageToggle={this.editImageToggle}
          handleImageChange={this.handleImageChange}
          isFetching={this.props.isFetching}
          saveImage={this.saveImage}
        />
      </div>
    );
  }
  public copyRestuarantToggle(): void {
    this.setState((prevState: any) => ({
      copymodel: !prevState.copymodel,
    }));
  }
  public setRestuarantCopy(event: any): void {
    this.setState({
      uuid: event.uuid,
    });
    this.copyRestuarantToggle();
  }
  public okCopyRestuarant(): void {
    api
      .post("/api/1.0/restaurant/" + this.state.uuid + "/copy", {})
      .then((response: any) => {
        if (!!response.status) {
          this.props.getRestaurant();
        } else {
          handleResponse(response);
        }
      });
    this.copyRestuarantToggle();
  }
  public restaurantList(data: any): any {
    let restaurant;
    if (!!data && data.length > 0) {
      restaurant = data.map((locales: any, i: number) => {
        return (
          <div key={i}>
            <RestaurantCard
              isSuperAdmin={localStorage.getItem(ISSUPERADMIN)}
              setRestuarantCopy={this.setRestuarantCopy.bind(this, locales)}
              setImageEdit={this.setImageEdit.bind(this, locales)}
              setEditable={this.setEditable.bind(this, locales)}
              setDelete={this.setDelete.bind(this, locales)}
              locales={locales}
            />
          </div>
        );
      });
      return restaurant;
    } else {
      return <Label>{this.props.t("common.noRecords")}</Label>;
    }
  }

  componentDidMount() {
    this.props.getLanguages();
    setLanguage(JSON.parse(localStorage.getItem("USERLANGUAGE") || ""));
    document.body.className = "light-theme";
    if (this.props.match.params.create === "true") {
      this._setStates("addmodal", true);
    } else {
      this._setStates("addmodal", false);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (!!newProps) {
      this._setStates("isFetching", newProps.isFetching);
      if (!!newProps && !newProps.isFetching && !newProps.failure) {
        this._setStates("restaurant", newProps.restaurant);
      }
      if (!!this.state.isUpdated && !newProps.failure && !newProps.isFetching) {
        this.props.getRestaurant();
        this._setStates("isUpdated", false);
      }
      if (
        !!newProps.restaurantupdate.status &&
        !!this.state.editmodal &&
        !newProps.isFetching
      ) {
        this.toggle();
      }
      if (
        !!newProps.restaurantadd.status &&
        !!this.state.addmodal &&
        !newProps.isFetching
      ) {
        this.addToggle();
      }
    }
  }

  validateInput = () => {
    let phoneRegex = /^\+?\d{5,15}$/;
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isValid: boolean = true;
    let errors: any = {};
    if (
      !phoneRegex.test(this.state.phone) &&
      this.state.phone?.trim() !== "" &&
      this.state.phone?.trim() !== undefined
    ) {
      errors["phone"] = this.props.t("error.invalidPhoneNumber");
      isValid = false;
    }
    if (!emailRegex.test(this.state.email)) {
      errors["email"] = this.props.t("error.invalidEmail");
      isValid = false;
    }
    if (
      this.state.accountingEmail.length > 0 &&
      !emailRegex.test(this.state.accountingEmail)
    ) {
      errors["accountingEmail"] = this.props.t("error.invalidEmail");
      isValid = false;
    }
    if (this.state.addmodal && !!!this.state.language.value) isValid = false;
    this.setState({
      errors: errors,
    });
    return isValid;
  };

  public saveEdit(): void {
    let language = JSON.parse(localStorage.getItem("USERLANGUAGE") || "[]");
    language = language.length > 0 ? language[0].code : "en-gb";

    if (!this.validateInput()) {
      return;
    }
    this.props.updateRestuaurant({
      uuid: this.state.uuid,
      data: {
        locales: {
          [language]: {
            name: this.state.name,
            address: this.state.address,
            description: this.state.description,
            city: this.state.city,
            country: this.state.country?.value,
          },
        },
        email: this.state.email,
        accounting_email: this.state.accountingEmail,
        phone: this.state.phone,
        vat_code: this.state.vatCode,
        zip: this.state.zip,
      },
    });
    this._setStates("isUpdated", true);
  }

  public saveAdd(): void {
    let language = JSON.parse(localStorage.getItem("USERLANGUAGE") || "[]");
    language = language.length > 0 ? language[0].code : "en-gb";
    if (!this.validateInput()) {
      return;
    }
    this.props.addRestuaurant({
      data: {
        locales: {
          [language]: {
            name: this.state.name,
            address: this.state.address,
            description: this.state.description,
            city: this.state.city,
            country: this.state.country?.value,
          },
        },
        email: this.state.email,
        phone: this.state.phone,
        accounting_email: this.state.accountingEmail,
        vat_code: this.state.vatCode,
        zip: this.state.zip,
        language: this.state.language.value,
      },
    });
    this._setStates("isUpdated", true);
  }

  public toggle(): void {
    this.setState((prevState: any) => ({
      editmodal: !prevState.editmodal,
    }));
  }
  public deleteToggle(): void {
    this.setState((prevState: any) => ({
      open: !prevState.open,
    }));
  }

  public deleteConfirmToggle(): void {
    this.props.removeRestuaurant({
      uuid: this.state.uuid,
    });
    this._setStates("isUpdated", true);
    this.deleteToggle();
  }

  public addToggle(): void {
    this.setState((prevState: any) => ({
      addmodal: !prevState.addmodal,
      name: "",
      address: "",
      description: "",
      city: "",
      country: "",
      email: "",
      accountingEmail: "",
      phone: "",
      vatCode: "",
      zip: "",
      errors: {},
    }));
  }

  public setImageEdit(event: any): void {
    this.setEditState(event);
    this.editImageToggle();
  }

  public setEditable(event: any): void {
    this.setEditState(event);
    this.toggle();
  }

  private setEditState(event: any) {
    let restaurantLocale = commonService.applyLocalization(
      "user",
      "name",
      event.locales
    );
    this.setState({
      uuid: event.uuid,
      name: restaurantLocale?.name,
      address: restaurantLocale?.address,
      description: restaurantLocale?.description,
      email: event.email,
      accountingEmail: event.accounting_email,
      city: restaurantLocale.city,
      country: restaurantLocale.country
        ? {
            label: restaurantLocale.country,
            value: restaurantLocale.country,
          }
        : "",
      phone: event.phone,
      vatCode: event.vat_code,
      zip: event.zip,
      errors: {},
      language: event.language,
    });
  }

  public editImageToggle(): void {
    this.setState((prevState: any) => ({
      imagemodal: !prevState.imagemodal,
      filedata: [],
    }));
  }

  public handleImageChange(e: any): void {
    let files = e.target.files[0];
    this._setStates("filedata", files);
  }

  public saveImage(): void {
    let formData = commonService.createImageFormData({
      object_type: "restaurant",
      object_uuid: this.state.uuid,
      images: this.state.filedata,
      entity_type: "restaurant_media",
      entity_uuid: this.state.uuid,
    });
    this.props.upload(formData);
    this._setStates("isUpdated", true);
    this.editImageToggle();
  }

  // Function binding

  private _setFunctionBindings(): void {
    this.setEditable = this.setEditable.bind(this);
    this.toggle = this.toggle.bind(this);
    this.addToggle = this.addToggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.deleteConfirmToggle = this.deleteConfirmToggle.bind(this);
    this.deleteToggle = this.deleteToggle.bind(this);
    this.saveEdit = this.saveEdit.bind(this);
    this.saveAdd = this.saveAdd.bind(this);
    this.setDelete = this.setDelete.bind(this);
    this.editImageToggle = this.editImageToggle.bind(this);
    this.saveImage = this.saveImage.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.setImageEdit = this.setImageEdit.bind(this);
  }

  public setDelete(event: any): void {
    this._setStates("uuid", event.uuid);
    this.deleteToggle();
  }

  private _setStates(name: string, value: any): void {
    this.setState({ [name]: value });
  }

  public handleChange(e: any): void {
    if (e.target.value.trim() !== "") {
      this._setStates(e.target.name, e.target.value);
    } else {
      this._setStates(e.target.name, "");
    }

    if (e.target.name === "phone") {
      this.setState({
        errors: {
          ...this.state.errors,
          phone: "",
        },
      });
    } else if (e.target.name === "email") {
      this.setState({
        errors: {
          ...this.state.errors,
          email: "",
        },
      });
    } else if (e.target.name === "accountingEmail") {
      this.setState({
        errors: {
          ...this.state.errors,
          accountingEmail: "",
        },
      });
    }
  }

  public handleCountryChange = (country: any) => {
    this._setStates("country", country);
  };

  public handleLanguageChange = (language: any) => {
    this._setStates("language", language);
  };
}
const mapStateToProps: any = (state: any) => {
  return {
    languages: state.languages.data,
    uploads: state.upload.data,
    restaurantupdate: state.restaurantupdate.data,
    restaurantadd: state.restaurantadd.data,
    restaurant: state.restaurant.data,
    isFetching:
      state.languages.isFetching ||
      state.restaurant.isFetching ||
      state.restaurantupdate.isFetching ||
      state.restaurantadd.isFetching ||
      state.restaurantremove.isFetching ||
      state.upload.isFetching,
    failure:
      state.languages.failure ||
      state.restaurant.failure ||
      state.restaurantupdate.failure ||
      state.restaurantadd.failure ||
      state.restaurantremove.failure ||
      state.upload.failure,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    getLanguages: (credentials: any) => {
      dispatch(languageRequest(credentials));
    },
    getRestaurant: () => {
      dispatch(restaurantRequest());
    },
    updateRestuaurant: (credentials: any) => {
      dispatch(restaurantUpdate(credentials));
    },
    addRestuaurant: (credentials: any) => {
      dispatch(restaurantAdd(credentials));
    },
    removeRestuaurant: (credentials: any) => {
      dispatch(restaurantRemove(credentials));
    },
    upload: (credentials: any) => {
      dispatch(upload(credentials));
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(RestaurantComponent))
);
