// Application constant

export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const USERNAME = "USERNAME";
export const ISSUPERADMIN = "ISSUPERADMIN";

export const LANG = "LANG";
export const PROFILE = "PROFILE";
export const PROFILEUUID = "PROFILEUUID";
export const RESTAURANTUUIDS = "RESTAURANTUUIDS";
export const SUPPLIERUUID = "SUPPLIERUUID";

export const SEGMENT = "SEGMENT";
export const DEBUG = "DEBUG";
export const DB = "DB";

export const REDIRECT_URL = "REDIRECT_URL";

// Color Codes

export const GREEN = "#07767c";
export const WHITE = "#FFFFFF";
export const BLACK = "#000000";
export const LIGHT_GREEN = "#08b4d0a8";
export const RED = "#ff0000";
export const LIGHT_GRAY = "#cccccc";
export const GRAY = "#5b5b5b";
export const PHILIPPINE_GRAY = "#8c8c8c";
export const DARK_GRAY = "#3a3838";
export const ORANGE = "#FFA500";
export const SERVER_GREEN = "#008000";

export const TRAFFICLIGHT_RED = "#dc3545";
export const TRAFFICLIGHT_YELLOW = "#ffbb00";
export const TRAFFICLIGHT_GREEN = "#00ff00";
export const GENERATED_EMAIL_PREFIX = "NO_EMAIL_";

export const DELIVERY_SCHEDULE = "delivery_schedule";
export const CILENT_ORDER_EMAIL_TEMPLATE = "client_order_email_template";
export const ACCOUNTING_EMAIL_TEMPLATE = "accounting_email_template";

export const INVENTORY_TYPE = {
  MANAGED: 1,
  NO_STOCK: 2,
  INFINITY: 3,
};

export const USER_RIGHTS = {
  MANAGE_RESTAURANT: "briefing_manage_restaurant",
  SUPER_ADMIN: "super_admin",
  VIEW_ITEM_PRICES: "view_item_prices",
  SUPPLIER_OFFERING_ONLY: "order_supplier_offering_only",
  VIEW_RECIPES: "view_recipes",
  VIEW_INGREDIENTS: "view_ingredients",
  VIEW_LABELS: "view_labels",
  VALIDATE_RECIPE: "validate_recipe",
};

export const AUTHORIZED_PATHS: Array<{ path: string; right: string }> = [
  {
    path: "/restaurant/:uuid/resource-cost",
    right: "view_item_prices",
  },
];

export const RECIPE_TYPE = {
  RECIPE: 1,
  INGREDIENT: 2,
  INGREDIENT_CLASS: 3,
  CHOICE: 4,
  DATE_ITEM: 5,
};

export const RECIPE_STATUS = {
  DRAFT: 1,
  PUBLISHED: 2,
  ARCHIVED: 3,
};

export enum CARD_SELECTION_STATE {
  DEFAULT = "default",
  EDIT = "edit",
  DELETE = "delete",
}

export const PAGE_VIEW_MODE = {
  BOX_VIEW: "boxView",
};

export const HTTP_STATUS_CODE = {
  SUCCESS: 200,
  UNAUTHORIZED: 401,
};

export const WEB_APP_CONFIG = {
  APP_DAILY_REFRESH_TIME: 4,
};

export const GUEST_GROUP = {
  STATUS_TYPE_OPEN: 1,
  STATUS_TYPE_CLOSED: 2,
};

export const TAG_DATATYPE = {
  NUMBER: "number",
  BOOLEAN: "boolean",
  TEXT: "text",
  CONTENT: "content",
};

export const ORDER_STATUS: Array<{ label: string; value: Number }> = [
  {
    label: "common.open",
    value: 0,
  },
  {
    label: "common.completed",
    value: 1,
  },
  {
    label: "common.deleted",
    value: 2,
  },
  {
    label: "common.started",
    value: 3,
  },
  {
    label: "common.placed",
    value: 4,
  },
  {
    label: "common.rejected",
    value: 5,
  },
];

export const ORDER_TYPE: Array<{ label: string; value: Number }> = [
  {
    label: "reports.tableOrder",
    value: 0,
  },
  {
    label: "reports.inventoryOrder",
    value: 1,
  },
  {
    label: "reports.externalOrder",
    value: 2,
  },
];
