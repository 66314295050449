import React from "react";
import Select from "react-select";
import {
  Button,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import "../../../styles/recipe.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import commonService from "../../../services/common.service";
import _ from "lodash";
import InlineEditInput from "../../inline-edit-input/inline-edit-input";
import MaskedInput from "react-maskedinput";
import ItemSelect from "./itemSelect";
import { RecipeEditHelper } from "../../../helpers/recipeEditHelper";

export default function ItemAddModal(props: any) {
  const { t, i18n } = useTranslation();

  const getUnits = () => {
    const units = Object.keys(props?.units).length > 0 ? props.units : [];
    let filteredUnits = units.filter(
      (item: any) => !item.recipe_uuid || item.recipe_uuid === props.elementId
    );
    if (_.size(props?.newUnit) > 0) {
      filteredUnits.push(props.newUnit);
    }
    filteredUnits = filteredUnits.map((unit: any) => ({
      value: unit.uuid,
      label: commonService.applyLocalization(
        "restaurant",
        "name",
        unit.locales
      )["name"],
      ...unit,
    }));

    return _.sortBy(filteredUnits, [
      (option: any) => option.label.toLowerCase(),
    ]);
  };

  const relatedUnits = (unitUuid?: string) => {
    if (!unitUuid) {
      return getUnits();
    }
    if ("NEW_RECIPE_UNIT" === unitUuid) {
      return getUnits()?.filter((item: any) => item.value === unitUuid);
    }
    return (
      RecipeEditHelper.getRelatedUnits(
        unitUuid,
        props.elementId,
        props.units
      )?.filter(
        (item: any) => !item.recipe_uuid || item.recipe_uuid === props.elementId
      ) || []
    );
  };

  const completionOption: Array<any> = [
    {
      label: t("recipe.noVerification"),
      value: 0,
    },
    {
      label: t("recipe.verifyTotalQuantity"),
      value: 1,
    },
    {
      label: t("recipe.adjustPerLabel"),
      value: 2,
    },
  ];

  return (
    <React.Fragment>
      <Modal isOpen={props.isModalOpen}>
        <ModalHeader toggle={props.handleCancel} className="modal-header">
          {props.textLabels.modalTitle}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="firstname">{t("common.name")}</Label>
              <Label className="required-star-style">*</Label>
              <Input
                type="text"
                required
                name="name"
                autoComplete="name"
                placeholder={t("common.enterName")}
                value={props.name}
                onChange={props.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="description">{t("common.description")}</Label>
              <Input
                type="textarea"
                style={{ minHeight: 200 }}
                required
                name="description"
                autoComplete="description"
                placeholder={t("common.enterDescription")}
                value={props.description}
                onChange={props.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="label">{t("label.labels")}</Label>
              <Select
                value={props.selectedLabels}
                onChange={props.handleLabelSelect}
                options={props.labelOptions}
                isMulti
                maxMenuHeight={200}
                placeholder={t("common.select")}
              />
            </FormGroup>
            <FormGroup>
              <Label for="printerName">{t("printer.printerName")}</Label>
              <Input
                type="text"
                required
                name="printerName"
                autoComplete="printerName"
                placeholder={t("common.enterPrinterName")}
                value={props.printerName}
                onChange={props.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="posName">{t("common.posName")}</Label>
              <Input
                type="text"
                required
                name="posName"
                autoComplete="posName"
                placeholder={t("common.enterPosName")}
                value={props.posName}
                onChange={props.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="outputUnit">{t("common.outputUnit")}</Label>
              <Label className="required-star-style">*</Label>
              <Input
                type="select"
                id="outputunituuid"
                required
                name="outputunituuid"
                onChange={props.handleUnitChange}
                value={props.outputunituuid}
                disabled={props?.importedFrom && props.importedFrom !== ""}
              >
                {!props.isEdit && (
                  <option value="">{t("common.selectOutputUnit")}</option>
                )}
                {relatedUnits(props.isEdit ? props.outputunituuid : null).map(
                  (unit: any, index: number) => (
                    <option value={unit.value} key={index}>
                      {unit.label}
                    </option>
                  )
                )}
                {!props.isEdit && (
                  <option value="NEW_UNIT" hidden={props?.isUnitDisabled}>
                    {t("unit.newUnit")}
                  </option>
                )}
              </Input>
            </FormGroup>
            {props?.area === "ingredient" &&
              (!props?.importedFrom || props.importedFrom === "") && (
                <FormGroup>
                  <Label for="offeredUnit">{t("common.offeredUnit")}</Label>
                  <Label className="required-star-style">*</Label>
                  <Input
                    type="select"
                    id="offeredUnitUuid"
                    name="offeredUnitUuid"
                    onChange={props.handleChange}
                    value={props.offeredUnitUuid}
                  >
                    {relatedUnits(props.outputunituuid).map(
                      (unit: any, index: number) => (
                        <option value={unit.value} key={index}>
                          {unit.label}
                        </option>
                      )
                    )}
                  </Input>
                </FormGroup>
              )}
            {props?.area === "recipe" && (
              <>
                <FormGroup>
                  <Label for="batchSize">{t("common.batchSize")}</Label>
                  <Label className="required-star-style">*</Label>
                  <Input
                    type="number"
                    required
                    name="batchSize"
                    autoComplete="batchSize"
                    onChange={props.handleChange}
                    placeholder={t("common.enterBatchSize")}
                    value={props.batchSize}
                    min="0"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="batchUnit">{t("common.batchUnit")}</Label>
                  <Input
                    type="select"
                    id="batchUnitUuid"
                    required
                    name="batchUnitUuid"
                    onChange={props.handleChange}
                    value={props.batchUnitUuid}
                  >
                    {relatedUnits(props.outputunituuid).map(
                      (unit: any, index: number) => (
                        <option value={unit.value} key={index}>
                          {unit.label}
                        </option>
                      )
                    )}
                  </Input>
                </FormGroup>
              </>
            )}
            {(props?.area === "ingredient" || props?.area === "recipe") && (
              <>
                <FormGroup>
                  <Label for="shelfLife">{t("common.shelfLife")}</Label>
                  <InlineEditInput
                    id={"shelfLife"}
                    name={"shelfLife"}
                    value={String(props.shelfLife)}
                    editElement={MaskedInput}
                    edit={true}
                    propsEdit={{
                      type: "text",
                      className: "masked-input",
                      mask: "11d 11:11:11",
                    }}
                    onChange={(name: string, val: any) =>
                      props.handleChange({
                        target: {
                          name: name,
                          value: val,
                        },
                      })
                    }
                    styleContainer={{ width: "100%", display: "block" }}
                  />
                </FormGroup>
              </>
            )}
            {props?.area === "recipe" && (
              <>
                <FormGroup>
                  <Label for="package_quantity">
                    {t("common.boxQuantity")}
                  </Label>
                  <Input
                    type="number"
                    required
                    name="package_quantity"
                    onChange={props.handleChange}
                    placeholder={t("common.enterBoxQuantity")}
                    value={props.package_quantity}
                    min="0"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="package_unit_uuid">
                    {t("common.boxOutputUnit")}
                  </Label>
                  <Input
                    type="select"
                    id="package_unit_uuid"
                    required
                    name="package_unit_uuid"
                    onChange={props.handleChange}
                    value={props.package_unit_uuid}
                  >
                    {relatedUnits(props.outputunituuid).map(
                      (unit: any, index: number) => (
                        <option value={unit.value} key={index}>
                          {unit.label}
                        </option>
                      )
                    )}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="package_unit_uuid">
                    {t("recipe.verifyOutputQuantity")}
                  </Label>

                  <Input
                    type="select"
                    id="confirmationCompletion"
                    required
                    name="confirmationCompletion"
                    onChange={props.handleChange}
                    value={props.confirmationCompletion}
                  >
                    {completionOption.map((option: any) => (
                      <option value={option.value} key={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </>
            )}
            {props.type === "newIngredientList" && (
              <>
                {typeof props?.supplierId !== "undefined" && (
                  <FormGroup>
                    <Label for="lastname">{t("supplier.supplier")}</Label>
                    <Input
                      type="select"
                      id="supplierId"
                      required
                      name="supplier"
                      onChange={props.handleSupplierSelectChange}
                      value={props.supplierId}
                    >
                      <option value="">
                        {t("supplier.selectSupplier")} ...
                      </option>
                      {props.supplierList}
                    </Input>
                  </FormGroup>
                )}
                {typeof props?.sku !== "undefined" && (
                  <FormGroup>
                    <Label for="ingredientname">{t("common.sku")}</Label>
                    <div className="center">
                      <Input
                        style={{ flex: 0.95 }}
                        type="text"
                        required
                        name="sku"
                        autoComplete="sku"
                        placeholder={t("common.enterSKU")}
                        value={props.sku}
                        onChange={props.handleChange}
                      />
                      <span style={{ flex: 0.1 }} onClick={props.setSKU}>
                        <FontAwesomeIcon
                          style={{
                            color: "#3f3f3f",
                            fontSize: 20,
                            marginLeft: 10,
                            marginTop: 8,
                          }}
                          icon={faPlusCircle}
                        />
                      </span>
                    </div>
                  </FormGroup>
                )}
                {typeof props?.barcode !== "undefined" && (
                  <FormGroup>
                    <Label for="barcode">{t("common.productBarcode")}</Label>
                    <Input
                      type="text"
                      required
                      name="barcode"
                      autoComplete="barcode"
                      placeholder={t("common.enterProductBarcode")}
                      value={props.barcode}
                      onChange={props.handleChange}
                    />
                  </FormGroup>
                )}
              </>
            )}
            <FormGroup>
              <Label for="replacementItem">
                {t("common.replacementArticle")}
              </Label>
              <ItemSelect
                value={props.replacementItem}
                handleChange={props.handleChange}
                labelOptions={props.labelOptions}
              />
            </FormGroup>
            <FormGroup>
              <Label for="color">{t("common.color")}</Label>
              <Input
                type="color"
                name="color"
                placeholder={t("common.color")}
                value={props.color}
                onChange={props.handleChange}
              />
            </FormGroup>
            {props.area === "recipe" && (
              <>
                <FormGroup>
                  <CustomInput
                    id={"printLabel"}
                    type="checkbox"
                    name={"printLabel"}
                    checked={props.printLabel}
                    onChange={props.handleChange}
                    label={t("common.printLabel")}
                  />
                </FormGroup>
                <FormGroup>
                  <CustomInput
                    id={"inheritTags"}
                    type="checkbox"
                    name={"inheritTags"}
                    checked={props.inheritTags}
                    onChange={props.handleChange}
                    label={t("recipe.inheritTagsAndLabels")}
                  />
                </FormGroup>
              </>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={props.handleCancel}>
            {t("common.cancel")}
          </Button>
          <Button
            color="info"
            onClick={props.saveItem}
            disabled={
              !props.name ||
              !!props.isFetching ||
              (props?.area === "recipe" && !props.batchSize) ||
              props.outputunituuid === ""
            }
          >
            {t("common.save")}
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}
