import React, { Component } from "react";
import { Button, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { create } from "apisauce";

import ModalFactory from "react-modal-promise";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";

import { ACCESS_TOKEN, RECIPE_TYPE } from "../../../constant/constant";
import { BASE_URL } from "../../../config/app.config";
import commonService from "../../../services/common.service";
import ItemAddModal from "../../../components/service-item/shared/itemAddModal";
import ItemWrapper from "../../../components/service-item/shared/itemWrapper";
import { OpenDeleteModal } from "../../../components/card-components/delete-card/delete-modal";
import LoaderComponent from "../../../components/loader/loader";
import handleResponse from "../../../services/response.service";
import ConceptHeaderComponent from "../../../components/navigation/navigation-header/concept-header/concept-header";
import RestaurantNavComponent from "../../../components/navigation/navigation-left/restaurant-navigation/restaurant-navigation";
import NavigationRightComponent from "../../../components/navigation/navigation-right/navigation-right";

import ChoiceItemListCard from "../../../components/service-item/choice-item/ChoiceItemListCard";

import {
  ItemChoiceInitState,
  ItemChoiceState,
} from "../../../models/itemChoice.model";

import { choiceItemUpdate } from "../../../redux/actions/choice.item.action";
import {
  recipeRemove,
  recipeUpdate,
  recipeDetailsRequest,
  recipeItemsRequest,
  clearRecipeItems,
  clearRecipeDetails,
} from "../../../redux/actions/recipe.action";
import {
  unitsAdd,
  unitsRemove,
  unitsRequest,
} from "../../../redux/actions/units.action";

import WarningComment from "../../../components/warning-comment/warning-comment";
import { withCardItemSelectionStateManager } from "../../../components/cardItemSelectionManager";
import _ from "lodash";
import { ingredientCostRequest } from "../../../redux/actions/ingredients.action";
import { getRestaurantSuppliers } from "../../../services/supplier.restaurants.service";
import { RESTAURANT_REQUEST } from "../../../config/api.config";
import { RecipeEditHelper } from "../../../helpers/recipeEditHelper";
import DragAndDropWrapper from "../../../components/drag-drop-component";

const api = create({
  baseURL: BASE_URL,
});
class AbstractIngredientDetailsPage extends Component<any> {
  changedItems: any = {};
  editDataTask: any = [];
  expandedTasks: Set<any> = new Set();
  state: ItemChoiceState;
  pageText: Object = {
    addButtonTitle: "",
    pageTitle: this.props.t("ingredient.ingredientClassDetails"),
  };
  editItemData: any = {};
  constructor(props: any) {
    super(props);
    this.changedItems = {
      allContent: new Set(),
      inlineContent: new Set(),
    };
    this.state = ItemChoiceInitState;
    api.setHeaders({
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      "Cache-Control": "no-cache",
      Locale: localStorage.getItem("i18nextLng") || "en-gb",
      "content-type": "application/json",
    });
  }

  componentDidMount() {
    document.body.className = "light-theme";
    this.getSuppliers();
    this.loadMetaData();
    this.props.getAllRecipes({
      uuid: this.props.match.params.uuid,
      type: "1,2",
    });
    this.props.getRecipeDetail({
      restaurantuuid: this.props.match.params.uuid,
      recipeuuid: this.props.match.params.abstractuuid,
    });
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      (Object.keys(this.props.allRecipes).length > 0 &&
        this.props.allRecipes?.filter(
          (item: any) => item.uuid !== this.props.match.params.abstractuuid
        ).length > 0 &&
        prevState.choiceOptions.length !== this.props.allRecipes.length &&
        prevState.choiceOptions.length === 0) ||
      JSON.stringify(prevProps.allRecipes) !==
        JSON.stringify(this.props.allRecipes)
    ) {
      this.updateIngredientList(this.props.allRecipes);
    }
    if (!!this.state.fetchData) {
      if (this.state.isDeleted) {
        this.setState({
          isDeleted: false,
        });
        this.props.history.push(
          `/restaurant/${this.props.match.params.uuid}/ingredient-class`
        );
      }
      this.props.getUnits({ restaurantuuid: this.props.match.params.uuid });
      this.props.getRecipeDetail({
        restaurantuuid: this.props.match.params.uuid,
        recipeuuid: this.props.match.params.abstractuuid,
      });

      this.setState({
        fetchData: false,
      });
    }

    if (
      JSON.stringify(prevProps.recipeServiceSets) !==
        JSON.stringify(this.props.recipeServiceSets) ||
      this.state.recipeServiceSets[this.state.elementId] !==
        this.props.recipeServiceSets
    ) {
      let serviceSets: any = {};
      serviceSets[this.state.elementId] = this.props.recipeServiceSets;
      const allServiceSets = {
        ...this.state.recipeServiceSets,
        ...serviceSets,
      };
      this.setState({
        recipeServiceSets: allServiceSets,
      });
    }

    if (prevProps.editRecipeStatus !== this.props.editRecipeStatus) {
      RecipeEditHelper.redirectNewRecipe(
        this.props.editRecipeStatus,
        this.props.match.params.abstractuuid,
        this.props.history
      );
    }
  }

  componentWillUnmount() {
    this.props.clearItemDetail();
    this.props.clearAllRecipes();
  }

  public updateIngredientList = (allRecipes: any) => {
    this.setState({
      choiceOptions:
        allRecipes &&
        allRecipes.length > 0 &&
        allRecipes
          ?.filter(
            (item: any) => item.uuid !== this.props.match.params.abstractuuid
          )
          .map((recipe: any) => {
            const data: any = {
              outputUnit: recipe.output_unit_uuid,
              value: recipe.uuid,
              label: commonService.applyLocalization(
                "restaurant",
                "name",
                recipe.locales
              )["name"],
            };
            if (
              recipe?.type === RECIPE_TYPE.INGREDIENT &&
              recipe?.supplier_uuid
            ) {
              const supplierName: string = this.getSupplierDetails(
                recipe?.supplier_uuid
              );

              if (supplierName) {
                data["label"] = data["label"] + ` (${supplierName}) `;
              }
            }
            return data;
          }),
    });
  };

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    let update: any = {};
    if (nextProps.isFetching !== prevState.isFetching) {
      update.isFetching = nextProps.isFetching;
    }
    if (
      !!nextProps.ingredientClassDetails &&
      nextProps.ingredientClassDetails !== prevState.ingredientClassDetails
    ) {
      update.ingredientClassDetails = nextProps.ingredientClassDetails;
    }
    if (nextProps.ingredientClassDetails?.updateId !== prevState?.updateId) {
      update.ingredientClassDetailsItems =
        nextProps.ingredientClassDetails?.items;
      update.updateId = nextProps.ingredientClassDetails?.updateId;
    }
    if (
      !!prevState.isAddItemModalOpen &&
      !nextProps.isFetching &&
      !!prevState.isUpdated
    ) {
      update.isAddItemModalOpen = false;
      update.isUpdated = false;
      update.fetchData = true;
    }
    if (!nextProps.isFetching && !!prevState.isUpdated) {
      update.isUpdated = false;
      update.fetchData = true;
    }
    return update;
  }

  public handleEditItemClick = () => {
    let choiceLocale = commonService.applyLocalization(
      "restaurant",
      "name",
      this.props.ingredientClassDetails?.locales
    );
    let allLabels = this.state.labels?.length ? this.state?.labels : [];
    this.editItemData = this.props.ingredientClassDetails;

    this.setState({
      color: this.props.ingredientClassDetails.color,
      description: choiceLocale["description"],
      elementId: this.props.match.params.abstractuuid,
      isAddItemModalOpen: !this.state.isAddItemModalOpen,
      isEdit: true,
      itemId: this.props.ingredientClassDetails.uuid,
      name: choiceLocale["name"],
      newUnit: null,
      posName: choiceLocale["pos_name"],
      printerName: choiceLocale["printer_name"],
      outputquantity: this.props.ingredientClassDetails.output_quantity,
      outputunituuid: this.props.ingredientClassDetails.output_unit_uuid,
      isManged: this.props.ingredientClassDetails.managed,
      selectedLabels: this.props.ingredientClassDetails.label_uuids.map(
        (label: any) => {
          return { value: label, label: this.labelName(label, allLabels) };
        }
      ),
      replacementItem: this.props.ingredientClassDetails.replacement_item_uuid,
    });
  };

  public labelName = (parent: any, filterResult: any) => {
    let name;
    if (!!filterResult) {
      name = filterResult.map((item: any, i: number) => {
        if (parent === item.uuid) {
          return commonService.applyLocalization(
            "restaurant",
            "name",
            item.locales
          )["name"];
        }
      });
      return name;
    }
  };

  public loadMetaData = () => {
    api
      .get(
        "/api/1.0/restaurant/" +
          this.props.match.params.uuid +
          "/get??sort=name:desc&components=unit, storage, label, room",
        {}
      )
      .then((response: any) => {
        const responseData: any = handleResponse(response);
        if (responseData.ok) {
          let options: Array<any> = [];
          response.data?.label?.map((labl: any) => {
            options.push({
              value: labl.uuid,
              label: commonService.applyLocalization(
                "restaurant",
                "name",
                labl?.locales
              ).name,
            });
          });
          this.setState({
            labelOptions: _.sortBy(options, "label"),
            labels: response.data.label ? response.data.label : [],
            rooms: response.data.room ? response.data.room : [],
            storage: response.data.storage ? response.data.storage : [],
          });
        } else {
          handleResponse(response);
        }
      });
  };

  public handleChange = (data: any) => {
    this.setState({
      [data.target.name]: data.target.value,
    });
  };
  public handleClick = (data: any) => {};

  handleImageUpdate = (imageId: string) => {
    const selectedItem: any = this.props.ingredientClassDetails;
    let locale = commonService.applyLocalization(
      "restaurant",
      "name",
      selectedItem?.locales
    );
    const payload: any = {
      restaurantuuid: this.props.match.params.uuid,
      uuid: this.state.elementId,
      data: {
        image_uuid: imageId,
        label_uuid: selectedItem?.label_uuids || [],
        color: selectedItem.color,
        locales: {
          [this.props.restaurantLang[0].code]: {
            name: locale.name,
            description: locale.description,
            pos_name: locale.pos_name,
            printer_name: locale.printer_name,
          },
        },
        output_unit_uuid: selectedItem.output_unit_uuid,
        product_barcode: selectedItem?.product_barcode || "",
        shelf_life: selectedItem?.shelf_life || undefined,
        sku: selectedItem.sku,
        supplier_uuid: selectedItem?.supplier_uuid
          ? selectedItem?.supplier_uuid
          : undefined,
      },
    };

    this.props.updateRecipe(payload);
    this.setState({ isUpdated: true });
  };

  public handleUnitChange = (e: any) => {
    if (e.target.value === "NEW_UNIT") {
      this.openNewUnitModal();
      return;
    }
    this.props.units.forEach((unit: any) => {
      if (unit.uuid === e.target.value) {
        this.setState({ outputunituuid: unit.uuid });
      } else if (e.target.value === "NEW_RECIPE_UNIT") {
        this.setState({
          outputunituuid: e.target.value,
          isUnitModalOpen: false,
        });
      } else {
        this.props.units.forEach((unit: any) => {
          if (unit.uuid === e.target.value) {
            this.setState({ outputunituuid: unit.uuid });
          } else if (e.target.value === "") {
            this.setState({
              outputunituuid: "",
            });
          }
        });
      }
    });
  };
  public isManaged = (data: any) => {
    this.setState({
      isManaged: data.target.value,
    });
  };

  public handleItemSave = () => {
    const body: any = {
      restaurantuuid: this.props.match.params.uuid,
      uuid: this.props.match.params.abstractuuid,
      data: {
        type: this.state.isEdit ? undefined : 3,
        output_unit_uuid: this.state.outputunituuid,
        color: this.state.color,
        label_uuid: this.state.selectedLabels.map((item: any) => item.value),
        locales: {
          [this.props.restaurantLang[0].code]: {
            name: this.state.name,
            description: this.state.description,
          },
        },
        replacement_item: this.state.replacementItem,
      },
    };
    if (body.data.output_unit_uuid === "NEW_RECIPE_UNIT") {
      body.data.output_unit_uuid = "";
      body.data["output_unit"] = this.state.newUnit;
      delete body.data.output_unit.uuid;
    }
    RecipeEditHelper.updateRecipe(
      this.editItemData,
      body,
      this.props.updateRecipe,
      this
    );
    this.setState({
      isUpdated: true,
    });
  };

  public handleLabelSelect = (selectedLabels: any) => {
    if (selectedLabels != null) {
      this.setState({
        selectedLabels,
      });
    } else {
      this.setState({
        selectedLabels: [],
      });
    }
  };

  public displayUnitName = (unitId: any) => {
    if (
      Object.keys(this.props.units).length > 0 &&
      !!this.props.ingredientClassDetails
    ) {
      let choiceUnit = this.props.units.filter(
        (unit: any) => unit.uuid === unitId
      )[0];
      return commonService.applyLocalization(
        "restaurant",
        "name",
        choiceUnit?.locales
      )["name"];
    }
  };

  getSupplierDetails = (supplierId: string) => {
    const supplierDetails = this.state.supplierList.find(
      (supplier: any) => supplier.uuid === supplierId
    );
    return commonService.applyLocalization(
      "restaurant",
      "name",
      supplierDetails?.locales
    )["name"];
  };

  getSuppliers = () => {
    const recipeId: string = this.props.match.params.recipeuuid;
    const restaurantId: string = this.props.match.params.uuid;
    const payload = {
      credentials: {
        recipeuuid: recipeId,
        restaurantuuid: restaurantId,
      },
    };
    getRestaurantSuppliers(RESTAURANT_REQUEST, payload).then((data) => {
      const responseData: any = handleResponse(data);
      if (responseData?.ok) {
        this.setState({
          supplierList: responseData.data,
        });
      }
    });
  };

  public handleDeleteChoiceItem = () => {
    OpenDeleteModal().then((data: any) => {
      this.props.removeRecipe({
        uuid: this.props.match.params.abstractuuid,
        restaurantuuid: this.props.match.params.uuid,
      });
      this.setState({
        isUpdated: true,
        isDeleted: true,
      });
    });
  };

  handleUnitUpdate = (value: any, area: string) => {
    if (area === "visibility") {
      this.setState({
        isUnitModalOpen: value,
        newUnit: null,
      });
    } else if (area === "save") {
      this.setState({
        newUnit: value,
        outputunituuid: value.uuid,
        isUnitModalOpen: false,
      });
    }
  };

  openNewUnitModal = () => {
    this.setState({
      isUnitModalOpen: true,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <ModalFactory />
        <LoaderComponent display={!!this.state.isFetching} />

        <ItemAddModal
          color={this.state.color}
          description={this.state.description}
          elementId={this.state.elementId}
          handleCancel={this.handleEditItemClick}
          handleChange={this.handleChange}
          handleLabelSelect={this.handleLabelSelect}
          handleUnitChange={this.handleUnitChange}
          isFetching={this.state.isFetching}
          isModalOpen={this.state.isAddItemModalOpen}
          labelOptions={this.state.labelOptions}
          labels={this.state.labels}
          name={this.state.name}
          newUnit={this.state.newUnit}
          outputunituuid={this.state.outputunituuid}
          posName={this.state.posName}
          printerName={this.state.printerName}
          saveItem={this.handleItemSave}
          selectedLabels={this.state.selectedLabels}
          textLabels={this.getAddModalLabel()}
          units={
            Object.keys(this.props.units).length > 0 ? this.props.units : []
          }
          replacementItem={this.state.replacementItem}
          isEdit={this.state.isEdit}
        />
        <div className="container-fluid">
          <ConceptHeaderComponent concept={"concept"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <RestaurantNavComponent
                display={"ingredient-class"}
                restaurantuuid={this.props.match.params.uuid}
              />
            </Col>
            <Col xl={8} lg={6}>
              <ItemWrapper
                area="substituteItemDetails"
                elementId={this.state.elementId}
                fetchData={this.fetchData}
                getUpdatedCostPrices={this.getCostDetails}
                getExtraDetails={this.getExtraDetails}
                handleUnitUpdate={this.handleUnitUpdate}
                hasDetails={false}
                hasTag={true}
                pageText={this.getPageTitle()}
                isDetailPage={true}
                isUnitModalOpen={this.state.isUnitModalOpen}
                itemData={[this.props.ingredientClassDetails]}
                itemsDetails={[this.props.ingredientClassDetails]}
                ingredientsCost={this.props.ingredientsCost}
                labels={this.state.labels}
                recipeServiceSet={this.state.recipeServiceSets}
                rooms={this.state.rooms}
                setItemId={this.setItemId}
                units={this.props.units}
                handleImageUpdate={this.handleImageUpdate}
                handleSelect={this.handleSelect}
                onItemDelete={this.handleDeleteChoiceItem}
                onItemEdit={this.handleEditItemClick}
                type="ingredient-class"
                tags={this.props?.tags}
              />
              <div className="dim-box mb-3">
                <Row className="app-row-padding">
                  <Col xs={12}>
                    <h4>
                      {t("choiceItem.items")}
                      <WarningComment data={null} />
                    </h4>
                    {this.renderItemsBlock()}
                  </Col>
                </Row>
                <div className="my-3 text-right">
                  <Button
                    color="info"
                    onClick={this.handleSortSave}
                    disabled={
                      this.state.isFetching ||
                      this.state.isSaveButtonDisabled ||
                      this.props.cardItemSelectionData.selectionState.mode ===
                        "edit"
                    }
                  >
                    {t("common.save")}
                  </Button>
                </div>
              </div>
            </Col>
            <Col xl={2} lg={3}>
              <NavigationRightComponent />
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }

  public getAddModalLabel = () => {
    return {
      modalTitle: this.state.isEdit
        ? this.props.t("ingredient.editIngredientClass")
        : this.props.t("ingredient.addIngredientClass"),
    };
  };

  getBaseUnitOptions = () => {
    if (!Array.isArray(this.props.units)) return;
    let units: Array<any> = [];
    const baseUnit: any = this.props.units?.find(
      (unit: any) => unit.uuid === this.props.settings.base_weight_unit
    );
    if (baseUnit) {
      units =
        commonService.getRelateUnitDropdowns(
          baseUnit.uuid,
          this.props.units,
          this.props.units,
          1,
          this.props.elementId
        ) || [];
    }
    return _.sortBy(units, [(option: any) => option.label.toLowerCase()]);
  };

  public getCostDetails = (ingredientId: any) => {
    this.props.getIngredientCost({
      restaurantuuid: this.props.match.params.uuid,
      uuid: ingredientId,
    });
  };

  public getPageTitle = () => {
    return {
      addButtonTitle: this.props.t(""),
      pageTitle: this.props.t("ingredient.ingredientClassDetails"),
    };
  };

  public fetchData = () => {
    this.setState({
      fetchData: true,
    });
  };

  public setItemId = (itemId: string) => {
    this.setState({
      elementId: itemId,
    });
  };

  public getDetails = (recipeId: any) => {
    this.setState({ isFetching: true });
    this.props.getRecipeDetail({
      restaurantuuid: this.props.match.params.uuid,
      recipeuuid: recipeId,
    });
  };

  public handleSelect = (key: any, recipeId: any) => {
    if (key === "tags") {
      if (
        !this.state.itemsDetails.some((item: any) => item.uuid === recipeId)
      ) {
        this.getDetails(recipeId);
        this.setState({ isUpdated: true });
      }
    } else if (key === "cost") {
      this.getCostDetails(recipeId);
      this.setState({ isUpdated: true });
    }
    this.setState({ elementId: recipeId });
  };

  public getExtraDetails = (data: any) => {
    return (
      <Row>
        <Col lg={3} sm={6}>
          Output Quantity{" "}
        </Col>
        <Col lg={6} sm={6}>
          {data.output_quantity} {this.displayUnitName(data.output_unit_uuid)}
        </Col>
      </Row>
    );
  };

  public handleItemAddSubmit = (data: any, type?: any) => {
    if (type !== "delete") {
      let index = this.props.ingredientClassDetails?.items.findIndex(
        (item: any) => item.uuid === data.uuid
      );
      var ingredientItems: any = this.props.ingredientClassDetails?.items.map(
        (element: any) => ({
          recipe_uuid: element.recipe_uuid,
          output_quantity: element.output_quantity,
        })
      );
      delete data.uuid;
      if (index !== -1) ingredientItems[index] = data;
      else {
        ingredientItems = [...ingredientItems, data];
      }
    } else {
      ingredientItems = data;
    }
    this.props.cardItemSelectionData.deselectCardItem();

    this.props.updateChoiceItems({
      restaurantuuid: this.props.match.params.uuid,
      uuid: this.props.match.params.abstractuuid,
      data: {
        items: ingredientItems,
      },
    });
    this.setState({
      isUpdated: true,
    });
  };

  public handleItemRemove = (itemId: any) => {
    OpenDeleteModal().then(() => {
      let items = this.props.ingredientClassDetails?.items
        .filter((choice: any) => choice.uuid !== itemId)
        .map((element: any) => ({
          recipe_uuid: element.recipe_uuid,
          output_quantity: element.output_quantity,
        }));
      this.handleItemAddSubmit(items, "delete");
    });
  };

  public handleItemUpdateCancel = () => {
    this.setState({
      editItemData: {
        name: "",
        date: "",
        isEdit: true,
      },
      isAddItemModalOpen: false,
      isEditModalOpen: false,
      itemModalData: {
        name: "",
        days: "",
        uuid: "",
      },
    });
  };

  public filterIngredients = (card?: any) => {
    if (card) {
      return (
        this.state.choiceOptions.length > 0 &&
        this.state.choiceOptions?.filter(
          (choice: any) =>
            this.state.ingredientClassDetailsItems?.findIndex(
              (item: any) =>
                item.recipe_uuid === choice.value &&
                item.recipe_uuid !== card.recipe_uuid
            ) === -1
        )
      );
    }

    return (
      this.state.choiceOptions.length > 0 &&
      this.state.choiceOptions?.filter(
        (choice: any) =>
          this.props.ingredientClassDetails?.items?.findIndex(
            (item: any) => item.recipe_uuid === choice.value
          ) === -1
      )
    );
  };

  public getChildrenProps = (card: any, index: any) => {
    const choiceOptions = this.filterIngredients(card);
    return {
      indx: index,
      key: card.uuid,
      cardItemData: card,
      cardItemId: card.uuid,
      recipeuuid: card.recipe_uuid,
      choiceOptions: choiceOptions,
    };
  };

  public reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  public onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    this.setState({ isSaveButtonDisabled: false });
    const itemsList = this.reorder(
      this.state.ingredientClassDetailsItems,
      result.source.index,
      result.destination.index
    );
    this.setState({
      ingredientClassDetailsItems: itemsList,
    });
  };

  public handleSortSave = () => {
    this.props.updateChoiceItems({
      restaurantuuid: this.props.match.params.uuid,
      uuid: this.props.match.params.abstractuuid,
      data: {
        items: this.state.ingredientClassDetailsItems.map((item: any) => ({
          recipe_uuid: item.recipe_uuid,
          output_quantity: item.output_quantity,
        })),
      },
    });
    this.setState({
      isUpdated: true,
    });
  };

  public renderItemsBlock = () => {
    return (
      <div>
        <DragAndDropWrapper
          getChildrenProps={this.getChildrenProps}
          onDragEnd={this.onDragEnd}
          itemsList={this.state.ingredientClassDetailsItems}
        >
          <ChoiceItemListCard
            key={this.props.key}
            allRecipes={this.props.allRecipes}
            hasPrice={false}
            isNewCardItem={false}
            onDeleteCard={this.handleItemRemove}
            onSaveCard={this.handleItemAddSubmit}
            parentCardItemSelectionData={this.props.cardItemSelectionData}
            displayUnitName={this.displayUnitName}
          />
        </DragAndDropWrapper>
        <ChoiceItemListCard
          key={"newCard"}
          cardItemData={{ type: "newCard" }}
          cardItemId={"newCard"}
          choiceOptions={this.filterIngredients()}
          isNewCardItem={true}
          onDeleteCard={this.handleItemRemove}
          onSaveCard={this.handleItemAddSubmit}
          parentCardItemSelectionData={this.props.cardItemSelectionData}
          displayUnitName={this.displayUnitName}
        />
      </div>
    );
  };

  public saveCard = () => {};
  showLoading = (state: boolean = true) => {
    this.setState({ isFetching: state });
  };
}

const mapStateToProps: any = (state: any) => {
  let isFetching: boolean =
    state.choiceUpdate.isFetching ||
    state.ingredientCost.isFetching ||
    state.recipedetails.isFetching ||
    state.reciperemove.isFetching ||
    state.recipeupdate.isFetching ||
    state.tagAssign.isFetching ||
    state.unitsremove.isFetching;
  let failure: boolean =
    state.choiceUpdate.failure ||
    state.ingredientCost.failure ||
    state.recipedetails.failure ||
    state.reciperemove.failure ||
    state.recipeupdate.failure ||
    state.unitsremove.failure;
  if (!!state.recipedetails.data && !failure && !isFetching) {
    state.recipedetails.data.updateId = Math.floor(Math.random() * 1000);
  }
  return {
    editRecipeStatus: state.recipeupdate.data,
    isFetching: isFetching,
    failure: failure,
    ingredientsCost: state.ingredientCost.data?.cost,
    recipeServiceSets: state.ingredientCost.data.serviceSets,
    removeItemData: [],
    restaurantLang: commonService.getRestaurantDetails()?.languages || [],
    allRecipes: state.allRecipeItems.data,
    ingredientClassDetails: state.recipedetails.data,
    settings: state.settings.data,
    units: state.units.data,
    unitsRemove: state.unitsremove.data,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    clearAllRecipes: (credentials: any) => {
      dispatch(clearRecipeItems(credentials));
    },
    clearItemDetail: (credentials: any) => {
      dispatch(clearRecipeDetails(credentials));
    },
    getAllRecipes: (credentials: any) => {
      dispatch(recipeItemsRequest(credentials));
    },
    getIngredientCost: (credentials: any) => {
      dispatch(ingredientCostRequest(credentials));
    },
    getRecipeDetail: (credentials: any) => {
      dispatch(recipeDetailsRequest(credentials));
    },
    getUnits: (credentials: any) => {
      dispatch(unitsRequest(credentials));
    },
    removeRecipe: (credentials: any) => {
      dispatch(recipeRemove(credentials));
    },
    updateChoiceItems: (credentials: any) => {
      dispatch(choiceItemUpdate(credentials));
    },
    updateRecipe: (credentials: any) => {
      dispatch(recipeUpdate(credentials));
    },
  };
};
const enhance = compose(
  withCardItemSelectionStateManager("Task"),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
);

export default withTranslation()(enhance(AbstractIngredientDetailsPage));
