import "../../styles/units.scss";

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import { UnitsInitState, UnitsState } from "../../models/units.model";
import {
  unitsAdd,
  unitsRemove,
  unitsRequest,
  unitsUpdate,
  conversionAdd,
  conversionEdit,
  conversionDelete,
} from "../../redux/actions/units.action";
import DeleteModalCard from "../card-components/delete-card/delete-card";
import UnitsAddCard from "../card-components/units-add-card/units-add-card";
import UnitsCard from "../card-components/units-card/units-card";
import UnitsEditCard from "../card-components/units-edit-card/units-edit-card";
import LoaderComponent from "../loader/loader";
import SettingsHeaderComponent from "../navigation/navigation-header/settings-header/settings-header";
import SettingsNavComponent from "../navigation/navigation-left/settings-navigation/settings-navigation";
import NavigationRightComponent from "../navigation/navigation-right/navigation-right";
import { parse } from "@fortawesome/fontawesome-svg-core";
import { setRestaurantLanguage } from "../../config/lang.config";
import { withTranslation } from "react-i18next";
import commonService from "../../services/common.service";
import {
  addUnits as addUnitService,
  removeGrossUnits,
} from "../../services/units.service";
import _ from "lodash";
import { compose } from "redux";
import { withCardItemSelectionStateManager } from "../cardItemSelectionManager";
import { RESTAURANT_REQUEST } from "../../config/api.config";
import Select from "react-select";
import {
  settingsAdd,
  settingsRequest,
} from "../../redux/actions/stations.action";
import { OpenDeleteModal } from "../card-components/delete-card/delete-modal";
import ModalFactory from "react-modal-promise";

class UnitsComponent extends Component<any> {
  state: UnitsState;
  stackedUnits: Array<String> = [];
  isItTempUnit: boolean = false;
  tempUnit: any = {};
  constructor(props: any) {
    super(props);
    this._setFunctionBindings();
    this.state = UnitsInitState;
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <ModalFactory />
        <LoaderComponent
          display={this.state.isFetching || this.props.isFetching}
        />
        <div className="container-fluid">
          <SettingsHeaderComponent settings={"settings"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <SettingsNavComponent
                display={"units"}
                restaurantuuid={this.props.match.params.uuid}
              />
            </Col>
            <Col xl={!!localStorage.getItem("WEBVIEW") ? 10 : 8} lg={6}>
              <Link
                to="#"
                color="info"
                className="unit-add"
                onClick={this.addToggle}
              >
                {t("unit.addUnits")}
              </Link>
              <div className="white-box mb-3">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={`b-cursor-p  ${
                        this.state.activeTab === "units" ? "active" : ""
                      }`}
                      onClick={() => this.handleTab("units")}
                    >
                      {t("unit.units")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={`b-cursor-p  ${
                        this.state.activeTab === "settings" ? "active" : ""
                      }`}
                      onClick={() => this.handleTab("settings")}
                    >
                      {t("common.settings")}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="units" className="pt-3">
                    {/* <h4 className="mt-3">{t("unit.units")}</h4> */}
                    {this.unitList()}
                  </TabPane>
                  <TabPane tabId="settings" className="pt-3">
                    {/* <h4 className="mt-3">{t("common.settings")}</h4> */}
                    {this.renderSettings()}
                  </TabPane>
                </TabContent>
              </div>
            </Col>
            <Col xl={2} lg={3}>
              <NavigationRightComponent />
            </Col>
          </Row>
        </div>

        {/*  Edit Units  */}
        <UnitsEditCard
          state={this.state}
          toggle={this.toggle}
          handleChange={this.handleChange}
          saveEdit={this.saveEdit}
          handleSelectChange={this.handleSelectChange}
        />

        {/*  Add Units  */}
        <UnitsAddCard
          handleChange={this.handleNewUnitChange}
          isModalOpen={this.state.isUnitModalOpen}
          payload={this.state.unitModalPayload}
          unitModalActions={this.handleNewUnitModalActions}
        />

        {/*           
        <UnitsAddCard
          state={this.state}
          addToggle={this.addToggle}
          handleChange={this.handleChange}
          saveAdd={this.saveAdd}
          handleSelectChange={this.handleSelectChange}
        /> */}

        {/* Delete Units */}

        <DeleteModalCard
          isOpen={this.state.open}
          isFetching={!!this.state.isFetching}
          okDelete={this.deleteConfirmToggle}
          cancelDelete={this.deleteToggle}
        />

        {/* Delete Converstion */}

        <DeleteModalCard
          isOpen={this.state.toggleDelete}
          isFetching={!!this.state.isFetching}
          okDelete={this.deleteConversion}
          cancelDelete={this.deleteConversionToggle}
        />
      </div>
    );
  }

  getRelatedUnits = (unit: any) => {
    const item: any = unit;
    item["conversions"] = [];
    if (unit.to_conversions?.length > 0) {
      unit.to_conversions.forEach((to: any) => {
        const toUnit = _.cloneDeep(
          this.state.units?.find(
            (unit: any) => unit.uuid === to.fromUnit && !unit?.recipe_uuid
          )
        );
        if (toUnit) {
          toUnit["conversionUuid"] = to.uuid;
          toUnit["isRelateTo"] = unit.uuid;
          toUnit["conversionFactor"] = to.conversion_factor;

          let nestedConversions: Array<any> = [];
          this.stackedUnits.push(toUnit.uuid);
          item.conversions.push(toUnit);
          if (toUnit?.to_conversions?.length > 0 && !toUnit?.recipe_uuid) {
            const conversions = this.getRelatedUnits(toUnit);
            nestedConversions = conversions?.conversions || [];
            item.conversions.push(...nestedConversions);
          }
        }
      });
    }

    return item;
  };

  getUnitDropdowns = (area?: string) => {
    let units: Array<any> = [];
    if (area === "baseKitchenUnit")
      units.push({
        value: null,
        label: this.props.t("common.select"),
      });
    this.state.units.forEach((unit: any) => {
      if (unit.recipe_uuid) return;
      units.push({
        label:
          commonService.applyLocalization("restaurant", "name", unit.locales)[
            "name"
          ] +
          `(${
            commonService.applyLocalization("restaurant", "name", unit.locales)[
              "symbol"
            ]
          })`,
        value: unit.uuid,
      });
    });

    return units;
  };

  renderSettings = () => {
    return (
      <div className="child-box mb-3">
        <Form>
          <FormGroup className="p-0 row">
            <Col sm="4" lg="4">
              <Label className="ml-4 mr-1 mt-2 pl-2">
                {this.props.t("unit.baseUnit")}
              </Label>
            </Col>
            <Col sm="6" lg="6">
              <Select
                name="baseUnit"
                value={this.state.baseUnit}
                onChange={this.handleBaseUnit}
                maxMenuHeight={200}
                options={this.getUnitDropdowns()}
              />
            </Col>
          </FormGroup>
          {this.state.isBaseUnitChanged && !this.state.isGrossUnitDeleted && (
            <Row>
              <Col>
                <Alert color="warning" className=" d-flex align-items-center">
                  {this.props.t("unit.baseUnitAlert")}
                  <Button
                    color="danger"
                    className="mx-3 my-1"
                    onClick={() => this.deleteGrossWeight()}
                  >
                    {this.props.t("common.delete")}
                  </Button>
                </Alert>
              </Col>
            </Row>
          )}

          <FormGroup className="p-0 row">
            <Col sm="4" lg="4">
              <Label className="ml-4 mr-1 mt-2 pl-2">
                {this.props.t("unit.baseKitchenUnit")}
              </Label>
            </Col>
            <Col sm="6" lg="6">
              <Select
                name="baseKitchenUnit"
                value={this.state.baseKitchenUnit}
                onChange={this.handleBaseKitchenUnit}
                maxMenuHeight={200}
                options={this.getUnitDropdowns("baseKitchenUnit")}
                placeholder={this.props.t("common.select")}
              />
            </Col>
          </FormGroup>
          <div className="d-flex justify-content-end">
            <Button
              color="info"
              onClick={this.updateBaseUnit}
              disabled={
                !this.state.baseUnit ||
                (this.state.baseUnit &&
                  !Object.keys(this.state.baseUnit).length)
              }
            >
              {this.props.t("common.save")}
            </Button>{" "}
          </div>
        </Form>
      </div>
    );
  };

  public unitList = () => {
    let data: any = [];
    this.stackedUnits = [];

    this.state.units?.forEach((unit: any) => {
      if (!unit?.from_conversions && !unit?.to_conversions) {
        const isExist: boolean = this.stackedUnits.includes(unit.uuid);
        if (!isExist) {
          data.push(unit);
          this.stackedUnits.push(unit.uuid);
        }
      } else if (!unit?.from_conversions && !unit?.recipe_uuid) {
        const isExist: boolean = this.stackedUnits.includes(unit.uuid);
        if (!isExist) {
          const newUnits: any = this.getRelatedUnits(unit);
          this.stackedUnits.push(newUnits.uuid);
          data.push(newUnits);
        }
      } else if (
        unit.from_conversions &&
        !unit?.recipe_uuid &&
        !this.stackedUnits.includes(unit.uuid)
      ) {
        const recipeUnits: any = unit.from_conversions?.filter(
          (fromUnit: any) => {
            const fromUnits: any = this.props.units?.filter(
              (allUnit: any) =>
                allUnit.uuid === fromUnit.toUnit && !!allUnit.recipe_uuid
            );
            return !!fromUnits?.length;
          }
        );

        if (
          unit.from_conversions?.length === recipeUnits?.length &&
          !unit?.recipe_uuid
        ) {
          const newUnits: any = this.getRelatedUnits(unit);
          this.stackedUnits.push(newUnits.uuid);
          data.push(newUnits);
        }
      }
    });

    let units;
    if (!!data && data.length > 0) {
      const unitList: Array<any> = [];
      data.forEach((unit: any) => {
        unit["nameLower"] = commonService
          .applyLocalization("restaurant", "name", unit.locales)
          ["name"].toLowerCase();
        unitList.push(unit);
      });

      units = _.sortBy(unitList, "nameLower").map((unit: any, i: number) => {
        return (
          !unit.recipe_uuid && (
            <React.Fragment key={i}>
              <UnitsCard
                key={"unit" + unit.uuid}
                allAnchorUnits={unitList}
                allUnits={this.state.units}
                restaurantCode={this.props.settings?.settings?.restaurant_code}
                cardItemSelectionData={this.props.cardItemSelectionData}
                handleUnitUnitEdit={this.handleUnitUnitEdit}
                isRecipeEditable={true}
                onConversionCancel={this.handleConversionCancel}
                onNewUnitSelection={this.onNewUnitSelection}
                saveConversion={this.saveConversion}
                setConversion={this.setConversion.bind(this, unit)}
                setConversionDelete={this.setConversionDelete.bind(this, unit)}
                setDelete={this.setDelete.bind(this, unit)}
                setEditable={this.setEditable.bind(this, unit)}
                setEditableConversion={this.setEditableConversion.bind(
                  this,
                  unit
                )}
                showModal={this.state.showModal}
                tempUnit={this.tempUnit}
                type="unit"
                unit={unit}
                unitName={this.unitName}
              />
            </React.Fragment>
          )
        );
      });
      return units;
    } else {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          {this.props.t("common.noRecords")}
        </div>
      );
    }
  };

  componentDidMount() {
    document.body.className = "light-theme";
    this.props.getUnits({
      restaurantuuid: this.props.match.params.uuid,
      global: true,
    });
    this.props.getSettings({ restaurantuuid: this.props.match.params.uuid });
    this._setStates("restaurantuuid", this.props.match.params.uuid);
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (!!newProps) {
      this._setStates("isFetching", newProps.isFetching);
      if (!!newProps && !newProps.isFetching && !newProps.failure) {
        this._setStates("units", newProps.units);

        let conversionOptions = newProps.units
          .filter((unit: any) => !unit.recipe_uuid)
          .map((item: any) => {
            const unitLocale = commonService.applyLocalization(
              "restaurant",
              "name",
              item.locales
            );
            return {
              value: item.uuid,
              label: unitLocale?.name,
              recipeId: item.recipe_uuid,
            };
          });
        this._setStates("conversionOptions", conversionOptions);
      }
      if (!!this.state.isUpdated && !newProps.isFetching) {
        this.props.getUnits({
          restaurantuuid: this.props.match.params.uuid,
          global: true,
        });
        this._setStates("isUpdated", false);
      }
      if (
        !!newProps.unitsupdate.status &&
        !!this.state.editmodal &&
        !newProps.isFetching
      ) {
        this.toggle();
      }
      if (
        !!newProps.unitsadd.status &&
        !!this.state.isUnitModalOpen &&
        !newProps.isFetching
      ) {
        this.addToggle();
      }
      if (!!this.state.showModal && !newProps.isFetching) {
        this.toggleModal();
      }
      if (!!newProps.settings.base_weight_unit && !!newProps.units.length) {
        const selectedBaseUnit = newProps.units.find(
          (unit: any) => unit.uuid === newProps.settings.base_weight_unit
        );
        this.setState({
          baseUnit: {
            label: commonService.applyLocalization(
              "restaurant",
              "name",
              selectedBaseUnit.locales
            )["name"],
            value: selectedBaseUnit.uuid,
          },
        });
      }
      if (!!newProps.settings.base_kitchen_unit && !!newProps.units.length) {
        const selectedBaseUnit = newProps.units.find(
          (unit: any) => unit.uuid === newProps.settings.base_kitchen_unit
        );
        this.setState({
          baseKitchenUnit: {
            label: commonService.applyLocalization(
              "restaurant",
              "name",
              selectedBaseUnit.locales
            )["name"],
            value: selectedBaseUnit.uuid,
          },
        });
      }
    }
  }

  public saveEdit(): void {
    this.props.updateUnits({
      restaurantuuid: this.state.restaurantuuid,
      uuid: this.state.uuid,
      data: {
        locales: {
          [this.props?.restaurantLang[0]?.code]: {
            name: this.state.name,
            symbol: this.state.symbol,
          },
        },
        recipe_uuid:
          this.state.selectedRecipe !== undefined
            ? this.state.selectedRecipe.value !== ""
              ? this.state.selectedRecipe.value
              : undefined
            : undefined,
      },
    });
    this._setStates("isUpdated", true);
  }

  public saveAdd(): void {
    if (this.isItTempUnit) {
      this.tempUnit = {
        label: this.state.unitModalPayload.name,
        value: "NEW_TEMP_UNIT",
        symbol: this.state.unitModalPayload.symbol,
      };
      this.addToggle();
    } else {
      this.saveUnit();
      setTimeout(() => {
        this._setStates("isUpdated", true);
      }, 100);
    }
  }

  saveUnit = () => {
    this.props.addUnits({
      restaurantuuid: this.state.restaurantuuid,
      data: {
        locales: {
          [this.props.restaurantLang[0].code]: {
            name: this.state.unitModalPayload.name,
            symbol: this.state.unitModalPayload.symbol,
          },
        },
        recipe_uuid:
          this.state.selectedRecipe !== undefined
            ? this.state.selectedRecipe.value !== ""
              ? this.state.selectedRecipe.value
              : undefined
            : undefined,
      },
    });
  };

  public toggle(): void {
    this.setState((prevState: any) => ({
      editmodal: !prevState.editmodal,
    }));
  }

  public deleteToggle(): void {
    this.setState((prevState: any) => ({
      open: !prevState.open,
    }));
  }

  public deleteConfirmToggle(type: any): void {
    this.props.removeUnits({
      uuid: this.state.uuid,
      restaurantuuid: this.state.restaurantuuid,
    });
    this._setStates("isUpdated", true);
    this.deleteToggle();
  }

  deleteGrossWeight = () => {
    OpenDeleteModal().then(() => {
      const payLoad = {
        credentials: {
          restaurantuuid: this.state.restaurantuuid,
        },
      };
      this.setState({ isFetching: true });
      removeGrossUnits(RESTAURANT_REQUEST, payLoad)
        .then((response: any) => {
          this.setState({
            isBaseUnitChanged: false,
            isFetching: false,
            isGrossUnitDeleted: true,
          });
        })
        .catch((err: any) => {
          this.setState({ isFetching: false });
        });
    });
  };

  public addToggle(): void {
    this.setState((prevState: any) => ({
      isUnitModalOpen: !prevState.isUnitModalOpen,
      unitModalPayload: {
        name: "",
        symbol: "",
      },
      selectedRecipe: {},
    }));
  }

  handleBaseKitchenUnit = (selectedOption: any) => {
    if (
      !!this.props.settings.base_weight_unit &&
      selectedOption.value !== this.props.settings.base_weight_unit
    ) {
      this.setState({
        baseKitchenUnit: selectedOption,
      });
    } else {
      this.setState({
        baseKitchenUnit: selectedOption,
      });
    }
  };

  handleBaseUnit = (selectedOption: any) => {
    if (
      !!this.props.settings.base_weight_unit &&
      selectedOption.value !== this.props.settings.base_weight_unit
    ) {
      this.setState({
        baseUnit: selectedOption,
        isBaseUnitChanged: true,
      });
    } else {
      this.setState({
        baseUnit: selectedOption,
        isBaseUnitChanged: false,
      });
    }
  };

  public handleConversionCancel = () => {
    this.isItTempUnit = false;
  };

  handleNewUnitChange = (e: any) => {
    const unitData: any = { ...this.state.unitModalPayload };
    unitData[e.target.name] = e.target.value;
    this.setState({
      unitModalPayload: unitData,
    });
  };

  handleNewUnitModalActions = (action: string, data?: any) => {
    if (action === "cancel") {
      this.setState({
        isUnitModalOpen: false,
      });
    } else if (action === "save") {
      this.handleNewUnitSave();
    }
  };

  handleNewUnitSave = () => {
    this.saveAdd();
  };

  handleUnitUnitEdit = (formData: any) => {
    this.setEditable(formData);
  };

  public handleSelectChange = (selectedOption: any) => {
    if (selectedOption != null) {
      this._setStates("selectedRecipe", selectedOption);
    } else {
      this._setStates("selectedRecipe", []);
    }
  };

  handleTab = (tabId: string) => {
    this.setState({
      activeTab: tabId,
    });
  };

  onNewUnitSelection = () => {
    this.isItTempUnit = true;
    this.addToggle();
  };

  public saveConversion = (formData: {
    conversion_factor: string;
    from_unit: string;
    to_unit: string;
    uuid?: string;
    sku?: string;
    barcode: string;
  }) => {
    this.setState({ isFetching: true });
    if (formData.from_unit === "NEW_TEMP_UNIT") {
      this.saveTempUnit(formData);
      return;
    }
    let body: any = {
      data: {
        conversion_factor: formData.conversion_factor,
        from_unit: formData.from_unit,
        to_unit: formData.to_unit,
        sku: formData.sku,
        barcode: formData.barcode,
      },
      restaurantuuid: this.state.restaurantuuid,
    };
    if (!!formData.uuid) {
      body.conversionuuid = formData.uuid;
      this.props.editConversion(body);
    } else {
      this.props.addConversion(body);
    }
    this.props.cardItemSelectionData.deselectCardItem();
    this._setStates("isUpdated", true);
  };

  saveTempUnit = (formData: {
    conversion_factor: string;
    from_unit: string;
    to_unit: string;
    uuid?: string;
  }) => {
    const conversionData: any = formData;
    const payLoad = {
      credentials: {
        restaurantuuid: this.state.restaurantuuid,
        data: {
          locales: {
            [this.props.restaurantLang[0].code]: {
              name: this.tempUnit.label,
              symbol: this.tempUnit.symbol,
            },
          },
          recipe_uuid:
            this.state.selectedRecipe !== undefined
              ? this.state.selectedRecipe.value !== ""
                ? this.state.selectedRecipe.value
                : undefined
              : undefined,
        },
      },
    };
    addUnitService(RESTAURANT_REQUEST, payLoad)
      .then((response: any) => {
        conversionData.from_unit = response.data.data.uuid;
        this.saveConversion(conversionData);
      })
      .catch((err: any) => {});
  };

  public toggleModal = (type: any = "") => {
    let showModal = this.state.showModal;
    this._setStates("showModal", !showModal);
  };
  public unitName = (unitId: any, type?: any) => {
    let name: any = "";
    name = this.state.units.find((unit: any) => unit.uuid === unitId);
    let unitLocale: any = commonService.applyLocalization(
      "restaurant",
      "name",
      name?.locales
    );

    let unitName: string = unitLocale["name"];
    if (type === "fullUnit") {
      unitName += `(${unitLocale["symbol"]})`;
    } else if (type === "symbol") {
      unitName = `(${unitLocale["symbol"]})`;
    }
    return unitName;
  };

  updateBaseUnit = () => {
    let settings: any = {};
    if (this.state.baseUnit?.value) {
      settings = {
        base_weight_unit: this.state.baseUnit.value,
      };
    }
    settings["base_kitchen_unit"] = this.state.baseKitchenUnit?.value || "";

    if (Object.keys(settings).length > 0)
      this.props.updateSettings({
        restaurantuuid: this.props.match.params.uuid,
        data: settings,
      });
  };

  private _setFunctionBindings(): void {
    this.setEditable = this.setEditable.bind(this);
    this.toggle = this.toggle.bind(this);
    this.addToggle = this.addToggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.deleteConfirmToggle = this.deleteConfirmToggle.bind(this);
    this.deleteToggle = this.deleteToggle.bind(this);
    this.saveEdit = this.saveEdit.bind(this);
    this.saveAdd = this.saveAdd.bind(this);
    this.setDelete = this.setDelete.bind(this);
  }

  private setEditState(event: any) {
    let unitLocale = commonService.applyLocalization(
      "restaurant",
      "name",
      event?.locales
    );
    this.setState({
      name: unitLocale?.name,
      symbol: unitLocale?.symbol,
      uuid: event?.uuid,
    });
  }

  public setEditable(event: any): void {
    this.toggle();
    this.setEditState(event);
  }

  public setEditableConversion(event: any, type: any, unitId: any): void {
    let conversionFactor: string = "";
    let conversionKey: string = "conversions";
    let conversionId: string = "";

    let key: string = "conversions";

    let otherUnit: any = {};
    otherUnit = event[key]
      .filter((item: any) => {
        if (item.uuid === unitId) {
          let conversionItem: any = item.to_conversions?.find(
            (conv: any) => conv.toUnit === item.uuid
          );
          // TODO need to remove ----------------------------------------
          if (!conversionItem)
            conversionItem = item.from_conversions?.find(
              (unit: any) => unit.fromUnit === item.uuid
            );
          conversionFactor =
            key == "to_conversions"
              ? 1 / conversionItem.conversion_factor
              : conversionItem.conversion_factor;
          conversionId = conversionItem.uuid;

          return item;
        }
      })
      .map((element: any) => {
        let filterLabel = this.state.units.filter(
          (unit: any) => unit.uuid === unitId
        );
        let unitLocale = commonService.applyLocalization(
          "restaurant",
          "name",
          filterLabel[0].locales
        );
        return {
          value: unitId,
          label: unitLocale.name,
        };
      });
    {
      /* TODO SR-74: Temporary fix for the JS calculation precision issue, need to be cleaned up in future */
    }

    this.setState({
      uuid: event.uuid,
      otherUnit: otherUnit[0],
      errorMsg: "",
      parameter:
        type == "convertTo"
          ? conversionFactor
          : String(conversionFactor).includes(".999")
          ? Math.round(Number(conversionFactor))
          : conversionFactor,
      conversion: [],
      conversionId: conversionId,
      recipeSpecific: event.recipe_uuid,
    });
  }

  public setConversion = (event: any, type: any) => {
    this.setState({
      uuid: event.uuid,

      errorMsg: "",
      otherUnit: "",
      parameter: "",
      recipeSpecific: event.recipe_uuid,
    });
    this.toggleModal(type);
  };

  public setDelete(event: any): void {
    this._setStates("uuid", event.uuid);
    this.deleteToggle();
  }
  public deleteConversionToggle = () => {
    this.setState((prevState: any) => ({
      toggleDelete: !prevState.toggleDelete,
    }));
  };
  public setConversionDelete(event: any, conversionId: any): void {
    this._setStates("deleteConversion", conversionId);
    this.deleteConversionToggle();
  }

  public deleteConversion = (event: any) => {
    this.props.deleteConversion({
      conversionuuid: this.state.deleteConversion,
      restaurantuuid: this.state.restaurantuuid,
    });
    this._setStates("isUpdated", true);
    this.deleteConversionToggle();
  };

  private _setStates(name: string, value: any): void {
    this.setState({ [name]: value });
  }

  public handleChange(e: any) {
    if (e.target.name === "parameter") {
      if (e.target.value.trim() === "") {
        this._setStates("errorMsg", "");
      } else if (isNaN(e.target.value)) {
        this._setStates(
          "errorMsg",
          this.props.t("error.conversionFactorNumericValue")
        );
      } else if (Number(e.target.value) == 0 || Number(e.target.value) < 0) {
        this._setStates(
          "errorMsg",
          this.props.t("error.conversionFactorGTzero")
        );
      } else {
        this._setStates("errorMsg", "");
      }
    }

    if (e.target.value.trim() !== "") {
      this._setStates(e.target.name, e.target.value);
    } else {
      this._setStates(e.target.name, "");
    }
  }
}

const mapStateToProps: any = (state: any) => {
  let isFetching =
    state.conversionAdd.isFetching ||
    state.conversionEdit.isFetching ||
    state.conversionDelete.isFetching ||
    state.settings.isFetching ||
    state.units.isFetching ||
    state.unitsremove.isFetching ||
    state.unitsadd.isFetching ||
    state.unitsupdate.isFetching;
  let failure =
    state.conversionEdit.failure ||
    state.conversionDelete.failure ||
    state.settings.failure ||
    state.units.failure ||
    state.unitsremove.failure ||
    state.unitsadd.failure ||
    state.unitsupdate.failure;
  return {
    restaurantLang: commonService.getRestaurantDetails()?.languages || [],
    settings: state.settings.data,
    unitsupdate: state.unitsupdate.data,
    unitsadd: state.unitsadd.data,
    units: state.units.data,
    isFetching: isFetching,
    failure: failure,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    addConversion: (credentials: any) => {
      dispatch(conversionAdd(credentials));
    },
    addUnits: (credentials: any) => {
      dispatch(unitsAdd(credentials));
    },
    deleteConversion: (credentials: any) => {
      dispatch(conversionDelete(credentials));
    },
    editConversion: (credentials: any) => {
      dispatch(conversionEdit(credentials));
    },
    getSettings: (credentials: any) => {
      dispatch(settingsRequest(credentials));
    },
    getUnits: (credentials: any) => {
      dispatch(unitsRequest(credentials));
    },
    removeUnits: (credentials: any) => {
      dispatch(unitsRemove(credentials));
    },
    updateSettings: (credentials: any) => {
      dispatch(settingsAdd(credentials));
    },
    updateUnits: (credentials: any) => {
      dispatch(unitsUpdate(credentials));
    },
  };
};
const enhance = compose(
  withCardItemSelectionStateManager("Units"),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
);

export default withTranslation()(enhance(UnitsComponent));
