import React from "react";
import { Badge, Col, CustomInput, Input, Label, Row } from "reactstrap";
import { prepSetProps } from "../../../models/task.model";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import ConditionalLinkArea from "../../conditionalLinkArea/conditionalLinkArea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faEdit,
  faSave,
  faTimes,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { GRAY } from "../../../constant/constant";
import { faPlusSquare } from "@fortawesome/free-regular-svg-icons";
import commonService from "../../../services/common.service";
import { isEmpty } from "lodash";

function RecipePreparationSet(props: prepSetProps) {
  const { t, i18n } = useTranslation();
  const {
    calculationQty,
    newPrepSet,
    preparationSetsOptions,
    prepSetEditEnabled,
    prepSetCalcUnitsOptions,
    selectedCalcUnit,
    selectedPrepSet,
    maintainIngQty,
    prepSetCurrentAction,
    batchSize,
    batchUnit,
  } = props.payload;
  const {
    activatePrepSet,
    clonePrepSet,
    deletePrepSet,
    handleChange,
    handlePrepSetCalcUnit,
    handlePrepSetSelection,
    savePrepSet,
    togglePrepEdit,
    saveBatchDetails,
  } = props.prepSetActions;
  const selectedBatchUnit = prepSetCalcUnitsOptions.find(
    (u) => u.value === batchUnit
  );
  return (
    <>
      <Row className="p-0 m-0">
        <Col md={3} xl={3}>
          <Label for="label">{t("recipe.selectPreparationSet")}</Label>
        </Col>
        <Col md={4} xl={4}>
          {!prepSetEditEnabled ? (
            <div>
              <Select
                value={selectedPrepSet}
                onChange={handlePrepSetSelection}
                options={preparationSetsOptions}
                maxMenuHeight={200}
                placeholder={t("common.select")}
              />

              {selectedPrepSet?.value && !selectedPrepSet?.active && (
                <Badge
                  color="primary"
                  pill
                  className="mr-2 pointer-cursor"
                  onClick={activatePrepSet}
                >
                  {t("common.setAsDefault")}
                </Badge>
              )}
            </div>
          ) : (
            <Input
              className="mr-2"
              type="text"
              name="prepSetName"
              onChange={handleChange}
              value={newPrepSet}
            />
          )}
        </Col>
        <Col md={3} xl={3}>
          {!prepSetEditEnabled ? (
            <div>
              <ConditionalLinkArea
                type="span"
                activeLink={true}
                hide={false}
                className="pointer-cursor mr-2"
                onClick={() => togglePrepEdit("edit")}
                data-toggle="tooltip"
                data-placement="left"
                title={t("common.edit")}
              >
                <FontAwesomeIcon
                  style={{
                    color: GRAY,
                    fontSize: 20,
                    marginRight: 10,
                    marginTop: 5,
                  }}
                  icon={faEdit}
                />
              </ConditionalLinkArea>
              {!selectedPrepSet?.active && (
                <ConditionalLinkArea
                  type="span"
                  activeLink={true}
                  hide={false}
                  className="pointer-cursor mr-2"
                  onClick={() => deletePrepSet()}
                  data-toggle="tooltip"
                  data-placement="left"
                  title={t("common.delete")}
                >
                  <FontAwesomeIcon
                    style={{
                      color: GRAY,
                      fontSize: 20,
                      marginRight: 10,
                      marginTop: 5,
                    }}
                    icon={faTrashAlt}
                  />
                </ConditionalLinkArea>
              )}
              <ConditionalLinkArea
                type="span"
                activeLink={true}
                hide={false}
                className="pointer-cursor mr-2"
                onClick={() => togglePrepEdit("add")}
                data-toggle="tooltip"
                data-placement="left"
                title={t("common.add")}
              >
                <FontAwesomeIcon
                  style={{
                    color: GRAY,
                    fontSize: 20,
                    marginRight: 10,
                    marginTop: 5,
                  }}
                  icon={faPlusSquare}
                />
              </ConditionalLinkArea>
              <ConditionalLinkArea
                type="span"
                activeLink={true}
                hide={false}
                className="pointer-cursor"
                onClick={() => clonePrepSet()}
                data-toggle="tooltip"
                data-placement="left"
                title={t("common.copy")}
              >
                <FontAwesomeIcon
                  style={{
                    color: GRAY,
                    fontSize: 20,
                    marginRight: 10,
                    marginTop: 5,
                  }}
                  icon={faCopy}
                />
              </ConditionalLinkArea>
            </div>
          ) : (
            <>
              <ConditionalLinkArea
                type="span"
                activeLink={true}
                className="pointer-cursor"
                onClick={savePrepSet}
                data-toggle="tooltip"
                data-placement="left"
                title={t("common.save")}
              >
                <FontAwesomeIcon
                  style={{
                    color: GRAY,
                    fontSize: 20,
                    marginRight: 10,
                    marginTop: 5,
                  }}
                  icon={faSave}
                />
              </ConditionalLinkArea>
              <ConditionalLinkArea
                type="span"
                activeLink={true}
                className="pointer-cursor"
                onClick={() => togglePrepEdit("cancel")}
                data-toggle="tooltip"
                data-placement="left"
                title={t("common.cancel")}
              >
                <FontAwesomeIcon
                  style={{
                    color: GRAY,
                    fontSize: 20,
                    marginRight: 10,
                    marginTop: 5,
                  }}
                  icon={faTimes}
                />
              </ConditionalLinkArea>
            </>
          )}
        </Col>
      </Row>
      <Row className="p-0 m-0 mt-1">
        <Col md={3} xl={3}>
          <Label for="label">{`${t("common.batchSize")}/${t(
            "common.unit"
          )}`}</Label>
        </Col>
        <Col md={4} xl={4}>
          {"batchEdit" === prepSetCurrentAction ? (
            <>
              <div className="d-flex align-items-center">
                <Input
                  className={`mr-2 ${
                    !(Number(batchSize) > 0) && "border border-danger"
                  }`}
                  type="number"
                  name="batchSize"
                  autoComplete="batchSize"
                  onChange={handleChange}
                  placeholder={t("common.enterBatchSize")}
                  value={batchSize}
                  min="0"
                />
                <Select
                  value={selectedBatchUnit}
                  onChange={(u: any) => {
                    handleChange({
                      target: { name: "batchUnit", value: u?.value },
                    });
                  }}
                  options={prepSetCalcUnitsOptions}
                  maxMenuHeight={200}
                  placeholder={t("common.select")}
                  className={`b-width-250 ${
                    isEmpty(batchUnit) && "border border-danger rounded"
                  }`}
                />
              </div>
            </>
          ) : (
            <div className="mr-2">
              {batchSize !== "" ? (
                <div>
                  <strong>
                    {batchSize} {selectedBatchUnit?.symbol}
                  </strong>
                </div>
              ) : (
                <>{t("recipe.notDefined")}</>
              )}
            </div>
          )}
        </Col>
        <Col md={3} xl={3}>
          {prepSetCurrentAction === "batchEdit" ? (
            <>
              <ConditionalLinkArea
                type="span"
                activeLink={true}
                className="pointer-cursor"
                onClick={saveBatchDetails}
                data-toggle="tooltip"
                data-placement="left"
                title={t("common.save")}
              >
                <FontAwesomeIcon
                  style={{
                    color: GRAY,
                    fontSize: 20,
                    marginRight: 10,
                    marginTop: 5,
                  }}
                  icon={faSave}
                />
              </ConditionalLinkArea>
              <ConditionalLinkArea
                type="span"
                activeLink={true}
                className="pointer-cursor"
                onClick={() => togglePrepEdit("cancel")}
                data-toggle="tooltip"
                data-placement="left"
                title={t("common.cancel")}
              >
                <FontAwesomeIcon
                  style={{
                    color: GRAY,
                    fontSize: 20,
                    marginRight: 10,
                    marginTop: 5,
                  }}
                  icon={faTimes}
                />
              </ConditionalLinkArea>
            </>
          ) : (
            <div>
              <ConditionalLinkArea
                type="span"
                activeLink={true}
                hide={false}
                className="pointer-cursor mr-2"
                onClick={() => togglePrepEdit("batchEdit")}
                data-toggle="tooltip"
                data-placement="left"
                title={t("common.edit")}
              >
                <FontAwesomeIcon
                  style={{
                    color: GRAY,
                    fontSize: 20,
                    marginRight: 10,
                    marginTop: 5,
                  }}
                  icon={faEdit}
                />
              </ConditionalLinkArea>
            </div>
          )}
        </Col>
      </Row>
      <Row className="p-0 m-0 mt-1">
        <Col md={3} xl={3}>
          <Label for="label">{t("recipe.calculationQuantity")}</Label>
        </Col>
        <Col md={4} xl={4}>
          {["add", "calcEdit"].includes(prepSetCurrentAction) ? (
            <>
              <div className="d-flex align-items-center">
                <Input
                  className={`mr-2   ${
                    calculationQty.trim() === "" && "border border-danger"
                  }`}
                  type="number"
                  name="calculationQty"
                  onChange={handleChange}
                  value={calculationQty}
                />
                <Select
                  value={selectedCalcUnit}
                  onChange={handlePrepSetCalcUnit}
                  options={prepSetCalcUnitsOptions}
                  maxMenuHeight={200}
                  placeholder={t("common.select")}
                  className={`b-width-250 ${
                    selectedCalcUnit === null && "border border-danger rounded"
                  }`}
                />
              </div>
              {prepSetCurrentAction === "calcEdit" && (
                <div className="d-flex align-items-center mt-1">
                  <CustomInput
                    id={"maintainIngQty"}
                    type="checkbox"
                    name={"maintainIngQty"}
                    checked={maintainIngQty}
                    onChange={handleChange}
                    label={t("recipe.keepIngredientQuantities")}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="mr-2">
              {calculationQty !== "" && selectedCalcUnit !== null ? (
                <div>
                  <strong>
                    {commonService.getSignificantDigit(calculationQty)}{" "}
                    {selectedCalcUnit?.symbol || ""}
                  </strong>{" "}
                </div>
              ) : (
                <>{t("recipe.notDefined")}</>
              )}
            </div>
          )}
        </Col>
        <Col md={3} xl={3}>
          {prepSetCurrentAction === "calcEdit" ? (
            <>
              <ConditionalLinkArea
                type="span"
                activeLink={true}
                className="pointer-cursor"
                onClick={savePrepSet}
                data-toggle="tooltip"
                data-placement="left"
                title={t("common.save")}
              >
                <FontAwesomeIcon
                  style={{
                    color: GRAY,
                    fontSize: 20,
                    marginRight: 10,
                    marginTop: 5,
                  }}
                  icon={faSave}
                />
              </ConditionalLinkArea>
              <ConditionalLinkArea
                type="span"
                activeLink={true}
                className="pointer-cursor"
                onClick={() => togglePrepEdit("cancel")}
                data-toggle="tooltip"
                data-placement="left"
                title={t("common.cancel")}
              >
                <FontAwesomeIcon
                  style={{
                    color: GRAY,
                    fontSize: 20,
                    marginRight: 10,
                    marginTop: 5,
                  }}
                  icon={faTimes}
                />
              </ConditionalLinkArea>
            </>
          ) : (
            prepSetCurrentAction !== "add" && (
              <div>
                <ConditionalLinkArea
                  type="span"
                  activeLink={true}
                  hide={false}
                  className="pointer-cursor mr-2"
                  onClick={() => togglePrepEdit("calcEdit")}
                  data-toggle="tooltip"
                  data-placement="left"
                  title={t("common.edit")}
                >
                  <FontAwesomeIcon
                    style={{
                      color: GRAY,
                      fontSize: 20,
                      marginRight: 10,
                      marginTop: 5,
                    }}
                    icon={faEdit}
                  />
                </ConditionalLinkArea>
              </div>
            )
          )}
        </Col>
      </Row>
    </>
  );
}

export default RecipePreparationSet;
