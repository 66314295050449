import { Component } from "react";
import { OpenConfirmModal } from "../components/card-components/confirm-card/confirm-modal";
import _ from "lodash";
import commonService from "../services/common.service";

export const RecipeEditHelper = {
  updateRecipe: (
    editedItem: any,
    body: any,
    updateRecipe: Function,
    component: Component
  ): void => {
    RecipeEditHelper.checkNewRecipeTrigger(editedItem, body)
      ? RecipeEditHelper.handleNewRecipeTrigger(body, updateRecipe, component)
      : updateRecipe(body);
  },

  checkNewRecipeTrigger: (editedItem: any, body: any): boolean => {
    return (
      (body.data?.output_quantity &&
        editedItem.output_quantity !== body.data.output_quantity) ||
      (body.data?.output_unit_uuid &&
        editedItem.output_unit_uuid !== body.data.output_unit_uuid) ||
      (body.data?.product_barcode &&
        editedItem.product_barcode !== body.data.product_barcode)
    );
  },

  handleNewRecipeTrigger: (
    body: any,
    updateRecipe: Function,
    component: Component
  ): void => {
    OpenConfirmModal()
      .then((accepted) => {
        body?.credentials?.data
          ? (body.credentials.data.new_recipe_trigger = true)
          : (body.data.new_recipe_trigger = true);
      })
      .finally(() => {
        updateRecipe(body);
        component.setState({ isUpdated: true });
      });
  },

  redirectNewRecipe: (
    responseStatus: any,
    recipeUuid: string,
    history: any
  ): void => {
    if (
      responseStatus?.recipeUuid &&
      responseStatus?.recipeUuid !== recipeUuid
    ) {
      const path: string = history.location.pathname.replace(
        /\/([a-f\d-]+)\/details/,
        `/${responseStatus.recipeUuid}/details`
      );
      history.replace(path);
    }
  },

  getRelatedUnits: (
    unit: any,
    recipeuuid: string,
    restaurantUnits: Array<any> = [],
    includeRecipeUnits: boolean = true
  ) => {
    if (unit) {
      const units: Array<any> = RecipeEditHelper.getRelatedUnitDropdowns(
        unit,
        recipeuuid,
        [],
        1,
        includeRecipeUnits,
        restaurantUnits
      );
      return units;
    }
    return [];
  },

  getRelatedUnitDropdowns: (
    unitId: string,
    recipeuuid: string,
    allUnits: Array<any>,
    conversionFactor: number,
    includeRecipeUnits: boolean,
    restaurantUnits: Array<any>
  ) => {
    const recipeUnit = Array.isArray(restaurantUnits)
      ? restaurantUnits?.find((unit: any) => {
          if (unit?.recipe_uuid) {
            return unit.uuid === unitId && unit?.recipe_uuid === recipeuuid;
          }
          return unit.uuid === unitId;
        })
      : null;

    if (!recipeUnit) return [];
    let units: Array<any> = [
      {
        label: commonService.applyLocalization(
          "restaurant",
          "name",
          recipeUnit.locales
        )["name"],
        value: recipeUnit.uuid,
        symbol: commonService.applyLocalization(
          "restaurant",
          "name",
          recipeUnit.locales
        )["symbol"],
        conversionFactor,
      },
    ];
    const fromUnits = recipeUnit?.from_conversions;
    const toUnits = recipeUnit?.to_conversions;

    if (fromUnits?.length > 0) {
      fromUnits.forEach((from: any) => {
        if (
          (recipeuuid &&
            from?.recipe_uuid &&
            recipeuuid !== from.recipe_uuid) ||
          (!recipeuuid && from?.recipe_uuid) ||
          (!includeRecipeUnits && from?.recipe_uuid)
        )
          return;
        const itsExist = allUnits?.filter(
          (allUnit: any) => allUnit?.value === from.toUnit
        );
        if (!itsExist?.length && recipeUnit.uuid !== from.toUnit) {
          const allUnitsMerged = _.concat(allUnits, units);
          const fromUnit: any = RecipeEditHelper.getRelatedUnitDropdowns(
            from.toUnit,
            recipeuuid,
            allUnitsMerged,
            conversionFactor / from.conversion_factor,
            includeRecipeUnits,
            restaurantUnits
          );

          if (!!fromUnit) units = _.concat(units, fromUnit);
        }
      });
    }

    if (toUnits?.length > 0) {
      toUnits.forEach((to: any) => {
        if (
          (to?.recipe_uuid && to.recipe_uuid !== recipeuuid) ||
          (!includeRecipeUnits && to?.recipe_uuid)
        )
          return;
        const itsExist = allUnits?.filter(
          (allUnit: any) => allUnit?.value === to.fromUnit
        );
        if (!itsExist?.length && recipeUnit.uuid !== to.fromUnit) {
          const allUnitsMerged = _.concat(allUnits, units);
          const toUnit: any = RecipeEditHelper.getRelatedUnitDropdowns(
            to.fromUnit,
            recipeuuid,
            allUnitsMerged,
            conversionFactor * to.conversion_factor,
            includeRecipeUnits,
            restaurantUnits
          );

          if (!!toUnit) units = _.concat(units, toUnit);
        }
      });
    }

    return _.uniqBy(units, function (e) {
      return e.value;
    });
  },
};
