import React, { Component } from "react";
import { Alert, Button, Card, CardBody, Col, Input, Row } from "reactstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { create } from "apisauce";

import ModalFactory from "react-modal-promise";
import { Prompt, withRouter } from "react-router";
import { withTranslation } from "react-i18next";

import { ACCESS_TOKEN } from "../../../constant/constant";
import { BASE_URL } from "../../../config/app.config";
import commonService from "../../../services/common.service";
import ItemAddModal from "../../../components/service-item/shared/itemAddModal";
import ItemWrapper from "../../../components/service-item/shared/itemWrapper";
import { OpenDeleteModal } from "../../../components/card-components/delete-card/delete-modal";
import LoaderComponent from "../../../components/loader/loader";
import handleResponse from "../../../services/response.service";
import ConceptHeaderComponent from "../../../components/navigation/navigation-header/concept-header/concept-header";
import RestaurantNavComponent from "../../../components/navigation/navigation-left/restaurant-navigation/restaurant-navigation";
import NavigationRightComponent from "../../../components/navigation/navigation-right/navigation-right";

import DateItemListCard from "../../../components/service-item/date-item/dateItemListCard";

import { choiceItemUpdate } from "../../../redux/actions/choice.item.action";
import {
  recipeRemove,
  recipeUpdate,
  recipeDetailsRequest,
  recipeItemsRequest,
  clearRecipeDetails,
} from "../../../redux/actions/recipe.action";
import {
  unitsAdd,
  unitsRemove,
  unitsRequest,
} from "../../../redux/actions/units.action";

import WarningComment from "../../../components/warning-comment/warning-comment";
import { withCardItemSelectionStateManager } from "../../../components/cardItemSelectionManager";
import {
  DateItemDetailInitState,
  DateItemDetailState,
} from "../../../models/date.item.model";
import {
  periodicityDetails,
  periodicityRequest,
} from "../../../redux/actions/periodicity.action";
import { Link } from "react-router-dom";
import _ from "lodash";
import { ingredientCostRequest } from "../../../redux/actions/ingredients.action";
import { RecipeEditHelper } from "../../../helpers/recipeEditHelper";

const api = create({
  baseURL: BASE_URL,
});
class DateItemDetailsPage extends Component<any> {
  changedItems: any = {};
  editDataTask: any = [];
  expandedTasks: Set<any> = new Set();
  state: DateItemDetailState;
  editItemData: any = {};
  constructor(props: any) {
    super(props);
    this.changedItems = {
      allContent: new Set(),
      inlineContent: new Set(),
    };
    this.state = DateItemDetailInitState;
    api.setHeaders({
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      "Cache-Control": "no-cache",
      Locale: localStorage.getItem("i18nextLng") || "en-gb",
      "content-type": "application/json",
    });
  }

  componentDidMount() {
    document.body.className = "light-theme";
    this.setState({ itemsList: [] }, () => {
      this.loadMetaData();
      this.props.getAllRecipes({
        uuid: this.props.match.params.uuid,
        type: "1,2,4",
      });
      this.props.getPeriodicityList({
        restaurantuuid: this.props.match.params.uuid,
      });
      this.props.getDateItemDetail({
        restaurantuuid: this.props.match.params.uuid,
        recipeuuid: this.props.match.params.dateItemUuid,
      });
      this.setState({
        isJustMounted: true,
        itemId: this.props.match.params.dateItemUuid,
      });
      this.getCostDetails(this.props.match.params.dateItemUuid);
    });

    window.onbeforeunload = (event: any) => {
      if (!_.isEqual(this.props.dateItemDetails.items, this.state.itemsList)) {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = "";
        }
        return "";
      } else {
        return;
      }
    };
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      !this.state.isFetching &&
      this.state.isJustMounted &&
      !!this.state.dateItemDetails?.periodicity_uuid &&
      this.state.dateItemDetails?.periodicity !== prevState.periodicity
    ) {
      this.getPeriodicityDetails(this.state.periodicity);
      this.setState({ isJustMounted: false });
    }
    if (
      this.state.isDeleted &&
      JSON.stringify(prevState.removeItemData) !==
        JSON.stringify(this.state.removeItemData)
    ) {
      this.setState({
        isDeleted: false,
        removeItemData: {},
      });
      this.props.history.push(
        `/restaurant/${this.props.match.params.uuid}/date-item`
      );
    }
    if (
      JSON.stringify(this.state.dateItemDetails) !==
        JSON.stringify(prevState.dateItemDetails) ||
      JSON.stringify(this.state.selectedPeriodicityData) !==
        JSON.stringify(prevState.selectedPeriodicityData)
    ) {
      this.handlePeriodicityErrorBlock();
    }

    if (
      Object.keys(this.state.dateItemUpdate).length !== 0 &&
      this.state.isAddItemModalOpen &&
      this.state.isUpdated
    ) {
      this.setState({
        isAddItemModalOpen: false,
        dateItemUpdate: {},
        newUnit: null,
        isUpdated: false,
      });

      setTimeout(() => {
        this.props.getUnits({ restaurantuuid: this.props.match.params.uuid });
        this.props.getDateItemDetail({
          restaurantuuid: this.props.match.params.uuid,
          recipeuuid: this.props.match.params.dateItemUuid,
        });
      }, 1000);
    }

    if (
      this.state.isUpdated &&
      !_.isEqual(
        this.props.dateItemRecipeUpdate,
        prevProps.dateItemRecipeUpdate
      )
    ) {
      this.setState({
        dateItemUpdate: {},
        isUpdated: false,
      });

      this.props.getDateItemDetail({
        restaurantuuid: this.props.match.params.uuid,
        recipeuuid: this.props.match.params.dateItemUuid,
      });
    }
    if (
      JSON.stringify(prevProps.recipeServiceSets) !==
        JSON.stringify(this.props.recipeServiceSets) ||
      this.state.recipeServiceSets[this.state.elementId] !==
        this.props.recipeServiceSets
    ) {
      let serviceSets: any = {};
      serviceSets[this.state.elementId] = this.props.recipeServiceSets;
      const allServiceSets = {
        ...this.state.recipeServiceSets,
        ...serviceSets,
      };
      this.setState({
        recipeServiceSets: allServiceSets,
      });
    }
    if (this.state.isUpdated && this.props.dateItemUpdate) {
      this.setState({
        isUpdated: false,
        isAddItemModalOpen: false,
      });
      setTimeout(() => {
        this.props.getUnits({ restaurantuuid: this.props.match.params.uuid });
        this.props.getDateItemDetail({
          restaurantuuid: this.props.match.params.uuid,
          recipeuuid: this.props.match.params.dateItemUuid,
        });
      }, 100);
    }
  }

  componentWillUnmount() {
    this.props.clearItemDetail();
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    let update: any = {};
    if (nextProps.isFetching !== prevState.isFetching) {
      update.isFetching = nextProps.isFetching;
    }
    if (
      !!Object.keys(nextProps.dateItemDetails).length &&
      nextProps.dateItemDetails !== prevState.dateItemDetails
    ) {
      let selectedPeriodicity: Array<string> = [];
      update.dateItemDetails = nextProps.dateItemDetails;

      update.itemsList = nextProps.dateItemDetails.items;
      if (!!nextProps.dateItemDetails.periodicity_uuid) {
        update.periodicity = nextProps.dateItemDetails.periodicity_uuid;
      } else {
        update.periodicity = "";
      }
      nextProps.dateItemDetails.items.map((item: any) => {
        selectedPeriodicity.push(item.periodicity_item_uuid);
      });
      update.selectedPeriodicity = selectedPeriodicity;
    }
    if (nextProps.units && !_.isEqual(nextProps.units, prevState.units)) {
      update.units = nextProps.units;
    }
    if (JSON.stringify(nextProps.removeItemData)) {
      update.removeItemData = nextProps.removeItemData;
    }
    if (
      !!Object.keys(nextProps.selectedPeriodicityData).length &&
      JSON.stringify(nextProps.selectedPeriodicityData) !==
        JSON.stringify(prevState.selectedPeriodicityData)
    ) {
      update.selectedPeriodicityData = nextProps.selectedPeriodicityData;
    }
    if (
      !!Object.keys(nextProps.dateItemUpdate).length &&
      JSON.stringify(nextProps.dateItemUpdate) !==
        JSON.stringify(prevState.dateItemUpdate)
    ) {
      update.dateItemUpdate = nextProps.dateItemUpdate;
    }

    return update;
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <Prompt
          when={
            !_.isEqual(this.props.dateItemDetails.items, this.state.itemsList)
          }
          message={t("common.routeChangeAlert")}
        />
        <ModalFactory />
        <LoaderComponent display={!!this.state.isFetching} />
        <ItemAddModal
          color={this.state.color}
          description={this.state.description}
          elementId={this.props.dateItemDetails.uuid}
          handleCancel={this.handleEditItemClick}
          handleChange={this.handleChange}
          handleLabelSelect={this.handleLabelSelect}
          handleUnitChange={this.handleUnitChange}
          isFetching={this.state.isFetching}
          isModalOpen={this.state.isAddItemModalOpen}
          labelOptions={this.state.labelOptions}
          labels={this.state.labels}
          name={this.state.name}
          newUnit={this.state.newUnit}
          outputunituuid={this.state.outputunituuid}
          posName={this.state.posName}
          printerName={this.state.printerName}
          saveItem={this.handleDateItemMetaSave}
          selectedLabels={this.state.selectedLabels}
          textLabels={this.getAddModalLabel()}
          units={this.props.units}
          replacementItem={this.state.replacementItem}
        />
        <div className="container-fluid">
          <ConceptHeaderComponent concept={"concept"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <RestaurantNavComponent
                display={"date-item"}
                restaurantuuid={this.props.match.params.uuid}
              />
            </Col>
            <Col xl={8} lg={6}>
              <ItemWrapper
                elementId={this.state.elementId}
                fetchData={this.fetchData}
                getExtraDetails={this.getExtraDetails}
                getUpdatedCostPrices={this.getCostDetails}
                handleImageUpdate={this.handleImageUpdate}
                hasDetails={false}
                hasTag={true}
                isDetailPage={true}
                itemData={[this.props.dateItemDetails]}
                itemsDetails={[this.props.dateItemDetails]}
                labels={this.state.labels}
                handleSelect={this.handleSelect}
                handleUnitUpdate={this.handleUnitUpdate}
                isUnitModalOpen={this.state.isUnitModalOpen}
                onItemDelete={this.handleDeleteDateItem}
                onItemEdit={this.handleEditItemClick}
                pageText={this.getPageTitle()}
                recipeServiceSet={this.state.recipeServiceSets}
                rooms={this.state.rooms}
                setItemId={this.setItemId}
                tags={this.props?.tags}
                type="choiceItem"
                units={this.props.units}
              />
              <div className="dim-box mb-3 bg-white">
                <Card className="mb-4">
                  <CardBody>
                    <Row>
                      <Col xl={3} lg={4}>
                        {t("periodicity.selectPeriodicity")}
                        <span className="text-danger">*</span>
                      </Col>
                      <Col lg={6}>
                        <Input
                          style={{
                            backgroundPosition:
                              "right calc(0.875rem + 0.1875rem) center",
                          }}
                          id="periodicity"
                          invalid={this.state.periodicity === ""}
                          name="periodicity"
                          onChange={this.handleChange}
                          type="select"
                          value={this.state.periodicity}
                        >
                          <option value="">{t("common.select")}</option>
                          {Object.keys(this.props.periodicityList).length !==
                            0 &&
                            this.props.periodicityList.map(
                              (periodicity: any) => (
                                <option
                                  key={periodicity.uuid}
                                  value={periodicity.uuid}
                                >
                                  {
                                    commonService.applyLocalization(
                                      "restaurant",
                                      "name",
                                      periodicity.locales
                                    )["name"]
                                  }
                                </option>
                              )
                            )}
                        </Input>
                      </Col>
                      <Col className="align-items-center d-flex">
                        <Link
                          to={`/restaurant/${this.props.match.params.uuid}/periodicity`}
                          className="mx-2"
                        >
                          {this.props.t("periodicity.managePeriodicity")}
                        </Link>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Row className="app-row-padding">
                  <Col xs={12}>
                    <h4>{t("choiceItem.items")}</h4>
                    {Object.keys(this.state.selectedPeriodicityErrors)
                      .length !== 0 &&
                      this.props.periodicityList.length > 0 &&
                      !this.state.isJustMounted && (
                        <Alert color="danger">
                          {this.props.t("dateItem.periodicityChanged")}
                        </Alert>
                      )}
                    {this.renderItemsBlock()}
                  </Col>
                </Row>
                <div className="my-3 text-right">
                  <Button
                    color="info"
                    onClick={this.handleDateItemSave}
                    disabled={
                      this.state.isFetching ||
                      this.state.periodicity === "" ||
                      Object.keys(this.state.selectedPeriodicityErrors)
                        .length !== 0 ||
                      this.props.cardItemSelectionData.selectionState.mode ===
                        "edit"
                    }
                  >
                    {t("common.save")}
                  </Button>
                </div>
              </div>
            </Col>
            <Col xl={2} lg={3}>
              <NavigationRightComponent />
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }

  public getCostDetails = (ingredientId: any) => {
    this.props.getIngredientCost({
      restaurantuuid: this.props.match.params.uuid,
      uuid: ingredientId,
    });
  };

  public getUnitName = () => {
    if (
      Object.keys(this.props.units).length > 0 &&
      !!this.props.dateItemDetails
    ) {
      let choiceUnit = this.props.units.filter(
        (unit: any) => unit.uuid === this.props.dateItemDetails.output_unit_uuid
      )[0];
      return commonService.applyLocalization(
        "restaurant",
        "name",
        choiceUnit?.locales
      )["name"];
    }
  };

  public fetchData = () => {
    this.setState({
      fetchData: true,
    });
  };

  public getDetails = (recipeId: any) => {
    this.setState({ isFetching: true });
    this.props.getDateItemDetail({
      restaurantuuid: this.props.match.params.uuid,
      recipeuuid: recipeId,
    });
  };

  public getExtraDetails = (data: any) => {
    return (
      <Row>
        <Col lg={3} sm={6}>
          {this.props.t("common.outputQuantity")}
        </Col>
        <Col lg={6} sm={6}>
          {data.output_quantity} {this.getUnitName()}
        </Col>
      </Row>
    );
  };
  public getOutputUnit = (recipeId: string) => {
    const allRecipes =
      Object.keys(this.props.allRecipes).length !== 0
        ? this.props.allRecipes
        : [];
    const allUnits =
      Object.keys(this.state.units).length !== 0 ? this.state.units : [];
    const selectedRecipeUnit = allRecipes.filter((recipe: any) => {
      return recipe.uuid === recipeId;
    })[0]?.output_unit_uuid;

    const [selectedUnit] = allUnits.filter((unit: any) => {
      return unit.uuid === selectedRecipeUnit;
    });

    if (!!selectedUnit?.locales) {
      const unitsData = commonService.applyLocalization(
        "restaurant",
        "name",
        selectedUnit.locales
      );
      return unitsData?.name || "";
    }
  };

  public getAddModalLabel = () => {
    return {
      modalTitle: this.state.isEdit
        ? this.props.t("dateItem.editDateItem")
        : this.props.t("dateItem.addDateItem"),
    };
  };

  getBaseUnitOptions = () => {
    if (!Array.isArray(this.props.units)) return;
    let units: Array<any> = [];
    const baseUnit: any = this.props.units?.find(
      (unit: any) => unit.uuid === this.props.settings.base_weight_unit
    );
    if (baseUnit) {
      units =
        commonService.getRelateUnitDropdowns(
          baseUnit.uuid,
          this.props.units,
          this.props.units,
          1,
          this.props.elementId
        ) || [];
    }
    return _.sortBy(units, [(option: any) => option.label.toLowerCase()]);
  };

  public getPageTitle = () => {
    return {
      pageTitle: this.props.t("dateItem.dateItemDetails"),
    };
  };

  public getPeriodicityDetails = (periodicity: string) => {
    this.props.getPeriodicityDetails({
      restaurantuuid: this.props.match.params.uuid,
      uuid: periodicity,
    });
  };

  public getPeriodicityLabel = (
    periodicityList: Array<any>,
    periodicity_item_uuid: string
  ) => {
    const [periodicity] = periodicityList.filter((periodicity: any) => {
      return periodicity.uuid === periodicity_item_uuid;
    });
    const label =
      commonService.applyLocalization(
        "restaurant",
        "name",
        periodicity?.locales
      )["name"] || null;
    return label;
  };

  public getRecipeListOptions = () => {
    let options: Array<object> = [];
    if (Object.keys(this.props.allRecipes).length !== 0) {
      this.props.allRecipes.map((recipe: any) => {
        options.push({
          value: recipe.uuid,
          label: commonService.applyLocalization(
            "restaurant",
            "name",
            recipe.locales
          )["name"],
        });
      });
    }
    return options;
  };

  public handleChange = (elem: any) => {
    this.setState({
      [elem.target.name]: elem.target.value,
    });

    if (elem.target.name === "periodicity") {
      this.getPeriodicityDetails(elem.target.value);
      this.handlePeriodicityErrorBlock();
    }
  };

  public handleDateItemMetaSave = () => {
    const body: any = {
      restaurantuuid: this.props.match.params.uuid,
      uuid: this.props.match.params.dateItemUuid,
      data: {
        output_unit_uuid: this.state.outputunituuid,
        color: this.state.color,
        label_uuid: this.state.selectedLabels.map((item: any) => item.value),
        locales: {
          [this.props.restaurantLang[0].code]: {
            description: this.state.description,
            name: this.state.name,
            pos_name: this.state.posName,
            printer_name: this.state.printerName,
          },
        },
        replacement_item: this.state.replacementItem,
      },
    };

    if (body.data.output_unit_uuid === "NEW_RECIPE_UNIT") {
      body.data.output_unit_uuid = "";
      body.data["output_unit"] = this.state.newUnit;
      delete body.data.output_unit.uuid;
    }
    this.props.updateDateItemMetaDetails(body);
    this.setState({ isUpdated: true });
  };

  public handleDateItemSave = () => {
    this.setState({ isFetching: true, isUpdated: true });
    let dateItems: any = [];
    this.state.itemsList.map((item: any) => {
      delete item.uuid;
      dateItems.push(item);
    });

    this.props.updateDateItems({
      restaurantuuid: this.props.match.params.uuid,
      uuid: this.props.match.params.dateItemUuid,
      data: {
        items: dateItems,
        periodicity: this.state.periodicity,
      },
    });
  };

  public handleDeleteDateItem = () => {
    OpenDeleteModal().then((data: any) => {
      this.props.removeDateItem({
        uuid: this.props.match.params.dateItemUuid,
        restaurantuuid: this.props.match.params.uuid,
      });
      this.setState({
        isUpdated: true,
        isDeleted: true,
      });
    });
  };

  public handleEditItemClick = () => {
    let dateItemLocale = commonService.applyLocalization(
      "restaurant",
      "name",
      this.props.dateItemDetails?.locales
    );
    let allLabels = this.state.labels?.length ? this.state?.labels : [];
    this.editItemData = this.props.dateItemDetails;
    this.setState({
      color: this.props.dateItemDetails.color,
      description: dateItemLocale["description"],
      isAddItemModalOpen: !this.state.isAddItemModalOpen,
      isEdit: true,
      itemId: this.props.dateItemDetails.uuid,
      name: dateItemLocale["name"],
      newUnit: null,
      posName: dateItemLocale["pos_name"],
      printerName: dateItemLocale["printer_name"],
      outputquantity: this.props.dateItemDetails.output_quantity,
      outputunituuid: this.props.dateItemDetails.output_unit_uuid,
      isManged: this.props.dateItemDetails.managed,
      selectedLabels: this.props.dateItemDetails.label_uuids.map(
        (label: any) => {
          return { value: label, label: this.labelName(label, allLabels) };
        }
      ),
      replacementItem: this.props.dateItemDetails.replacement_item_uuid,
    });
  };

  handleImageUpdate = (imageId: string) => {
    const selectedItem: any = this.props.dateItemDetails;
    let locale = commonService.applyLocalization(
      "restaurant",
      "name",
      selectedItem?.locales
    );
    const payload: any = {
      restaurantuuid: this.props.match.params.uuid,
      uuid: this.state.elementId,
      data: {
        image_uuid: imageId,
        label_uuid: selectedItem?.label_uuids || [],
        color: selectedItem.color,
        locales: {
          [this.props.restaurantLang[0].code]: {
            name: locale.name,
            description: locale.description,
            pos_name: locale.pos_name,
            printer_name: locale.printer_name,
          },
        },
        output_unit_uuid: selectedItem.output_unit_uuid,
        product_barcode: selectedItem?.product_barcode || "",
        shelf_life: selectedItem?.shelf_life || undefined,
        sku: selectedItem.sku,
        supplier_uuid: selectedItem?.supplier_uuid
          ? selectedItem?.supplier_uuid
          : undefined,
      },
    };

    this.props.updateDateItemMetaDetails(payload);
    this.setState({ isUpdated: true });
  };

  public handleItemSave = (data: any, cardId: any, periodicityDetails: any) => {
    let dateItems: any = [];
    let selectedPeriodicity: any = [...this.state.selectedPeriodicity];
    this.state.itemsList.map((item: any) => {
      dateItems.push(item);
    });
    if (cardId === "newCard") {
      dateItems.push(data);
      selectedPeriodicity.push(data.periodicity_item_uuid);
    } else {
      dateItems[cardId] = data;
      selectedPeriodicity[cardId] = data.periodicity_item_uuid;
    }
    this.props.cardItemSelectionData.deselectCardItem();
    this.setState(
      {
        itemsList: dateItems,
        selectedPeriodicity,
      },
      this.handlePeriodicityErrorBlock
    );
  };

  public handleItemRemove = (indx: any) => {
    OpenDeleteModal().then(() => {
      let items = [...this.state.itemsList];
      let selectedPeriodicity: any = [...this.state.selectedPeriodicity];
      let selectedPeriodicityErrors: any = {
        ...this.state.selectedPeriodicityErrors,
      };
      this.props.cardItemSelectionData.deselectCardItem();
      items.splice(indx, 1);
      selectedPeriodicity.splice(indx, 1);
      delete selectedPeriodicityErrors[indx];
      this.setState(
        {
          itemsList: items,
          selectedPeriodicity,
          selectedPeriodicityErrors,
        },
        this.handlePeriodicityErrorBlock
      );
    });
  };

  public handleItemUpdateCancel = () => {
    this.setState({
      editItemData: {
        name: "",
        date: "",
        isEdit: true,
      },
      isAddItemModalOpen: false,
      isEditModalOpen: false,
      itemModalData: {
        name: "",
        days: "",
        uuid: "",
      },
    });
  };

  public handleLabelSelect = (selectedLabels: any) => {
    if (selectedLabels != null) {
      this.setState({
        selectedLabels,
      });
    } else {
      this.setState({
        selectedLabels: [],
      });
    }
  };

  public handlePeriodicityErrorBlock = () => {
    let errorList: any = {};
    this.state.itemsList.map((card: any, index: number) => {
      const label = this.getPeriodicityLabel(
        this.state.selectedPeriodicityData?.items || [],
        card.periodicity_item_uuid
      );
      if (label === null) {
        errorList[index] = "error";
      }
    });
    this.setState({ selectedPeriodicityErrors: errorList });
  };

  public handleSelect = (key: any, recipeId: any) => {
    if (key === "tags") {
      if (
        !this.state.itemsDetails.some((item: any) => item.uuid === recipeId)
      ) {
        this.getDetails(recipeId);
        this.setState({ isUpdated: true });
      }
    }
    this.setState({ elementId: recipeId });
  };

  public handleUnitChange = (e: any) => {
    if (e.target.value === "NEW_UNIT") {
      this.openNewUnitModal();
      return;
    } else if (e.target.value === "NEW_RECIPE_UNIT") {
      this.setState({
        outputunituuid: e.target.value,
        isUnitModalOpen: false,
      });
    } else {
      let outputUnit: string = "";
      this.props.units.forEach((unit: any) => {
        if (unit.uuid === e.target.value) {
          outputUnit = unit.uuid;
        } else if (e.target.value === "") {
          outputUnit = "";
        }
      });
      this.setState({
        outputunituuid: outputUnit,
        isUnitModalOpen: false,
      });
    }
  };

  handleUnitUpdate = (value: any, area: string) => {
    if (area === "visibility") {
      this.setState({
        isUnitModalOpen: value,
        newUnit: null,
      });
    } else if (area === "save") {
      this.setState({
        newUnit: value,
        outputunituuid: value.uuid,
        isUnitModalOpen: false,
      });
    }
  };

  public labelName = (parent: any, filterResult: any) => {
    let name;
    if (!!filterResult) {
      name = filterResult.map((item: any, i: number) => {
        if (parent === item.uuid) {
          return commonService.applyLocalization(
            "restaurant",
            "name",
            item?.locales
          ).name;
        }
      });
      return name;
    }
  };

  public loadMetaData = () => {
    api
      .get(
        "/api/1.0/restaurant/" +
          this.props.match.params.uuid +
          "/get??sort=name:desc&components=unit, storage, label, room",
        {}
      )
      .then((response: any) => {
        const responseData: any = handleResponse(response);
        if (responseData.ok) {
          let options: Array<any> = [];
          response.data?.label?.map((labl: any) => {
            options.push({
              value: labl.uuid,
              label: commonService.applyLocalization(
                "restaurant",
                "name",
                labl?.locales
              ).name,
            });
          });
          this.setState({
            labelOptions: _.sortBy(options, "label"),
            labels: response.data.label ? response.data.label : [],
            rooms: response.data.room ? response.data.room : [],
            storage: response.data.storage ? response.data.storage : [],
            units: response.data.unit ? response.data.unit : [],
          });
        } else {
          handleResponse(response);
        }
      });
  };

  openNewUnitModal = () => {
    this.setState({
      isUnitModalOpen: true,
    });
  };

  public renderItemsBlock = () => {
    return (
      <div>
        {Object.keys(this.props.allRecipes).length !== 0 &&
          this.state.itemsList.map((card: any, index: number) => {
            const label = this.getPeriodicityLabel(
              this.state.selectedPeriodicityData?.items || [],
              card.periodicity_item_uuid
            );

            return (
              <DateItemListCard
                key={index}
                allRecipes={this.props.allRecipes}
                cardItemData={card}
                cardItemId={index}
                getOutputUnit={this.getOutputUnit}
                isNewCardItem={false}
                onDeleteCard={this.handleItemRemove}
                onSaveCard={this.handleItemSave}
                parentCardItemSelectionData={this.props.cardItemSelectionData}
                periodicityLabel={label}
                periodicityList={
                  this.state.selectedPeriodicityData?.items || []
                }
                recipeListOptions={this.getRecipeListOptions()}
                selectedPeriodicity={this.state.selectedPeriodicity}
                saveButton={false}
              />
            );
          })}
        <DateItemListCard
          key={"newCard"}
          cardItemData={{ type: "newCard" }}
          cardItemId={"newCard"}
          getOutputUnit={this.getOutputUnit}
          isNewCardItem={true}
          onDeleteCard={this.handleItemRemove}
          onSaveCard={this.handleItemSave}
          parentCardItemSelectionData={this.props.cardItemSelectionData}
          periodicityLabel=""
          periodicityList={this.state.selectedPeriodicityData?.items || []}
          recipeListOptions={this.getRecipeListOptions()}
          selectedPeriodicity={this.state.selectedPeriodicity}
        />
      </div>
    );
  };

  public setItemId = (itemId: string) => {
    this.setState({
      elementId: itemId,
    });
  };

  showLoading = (state: boolean = true) => {
    this.setState({ isFetching: state });
  };
}

const mapStateToProps: any = (state: any) => {
  return {
    isFetching:
      state.choiceUpdate.isFetching ||
      state.periodicity.isFetching ||
      state.periodicityDetails.isFetching ||
      state.recipedetails.isFetching ||
      state.reciperemove.isFetching ||
      state.recipeupdate.isFetching ||
      state.tagAssign.isFetching ||
      state.units.isFetching ||
      state.unitsremove.isFetching,
    failure:
      state.choiceUpdate.failure ||
      state.periodicity.failure ||
      state.periodicityDetails.failure ||
      state.recipedetails.failure ||
      state.reciperemove.failure ||
      state.recipeupdate.failure ||
      state.units.failure ||
      state.unitsremove.failure,
    allRecipes: state.allRecipeItems.data,
    dateItemDetails: state.recipedetails.data,
    dateItemUpdate: state.recipeupdate.data,
    dateItemRecipeUpdate: state.choiceUpdate.data,
    editRecipeStatus: state.recipeupdate.data,
    periodicityList: state.periodicity.data,
    recipeServiceSets: state.ingredientCost.data.serviceSets,
    removeItemData: state.reciperemove.data,
    restaurantLang: commonService.getRestaurantDetails()?.languages || [],
    selectedPeriodicityData: state.periodicityDetails.data,
    settings: state.settings.data,
    units: state.units.data,
    unitsRemove: state.unitsremove.data,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    clearItemDetail: (credentials: any) => {
      dispatch(clearRecipeDetails(credentials));
    },
    getAllRecipes: (credentials: any) => {
      dispatch(recipeItemsRequest(credentials));
    },
    getDateItemDetail: (credentials: any) => {
      dispatch(recipeDetailsRequest(credentials));
    },
    getIngredientCost: (credentials: any) => {
      dispatch(ingredientCostRequest(credentials));
    },
    getPeriodicityDetails: (credentials: any) => {
      dispatch(periodicityDetails(credentials));
    },
    getPeriodicityList: (credentials: any) => {
      dispatch(periodicityRequest(credentials));
    },
    getUnits: (credentials: any) => {
      dispatch(unitsRequest(credentials));
    },
    removeDateItem: (credentials: any) => {
      dispatch(recipeRemove(credentials));
    },
    updateDateItemMetaDetails: (credentials: any) => {
      dispatch(recipeUpdate(credentials));
    },
    updateDateItems: (credentials: any) => {
      dispatch(choiceItemUpdate(credentials));
    },
  };
};
const enhance = compose(
  withCardItemSelectionStateManager("Task"),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
);

export default withTranslation()(enhance(DateItemDetailsPage));
